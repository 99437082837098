import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import fire from "./services/fire";
import AppGlobalContext from "./AppGlobalContext";
import { attachUserDataListener } from "./services/user";
import HomePage from "./core/homePage";
import LoadingSpinner from "./core/common/loadingSpinner";
import RegisterForm from "./core/account/page/register";
import LoginForm from "./core/account/page/login";
import AccountSetup from "./core/account/page/accountSetup";
import AccountSetupSuccess from "./core/account/page/accountSetupSuccess";
import ForgotPassword from "./core/account/page/forgot";
import RegisterSuccess from "./core/account/page/registerSuccess";
import AuthHandler from "./core/account/page/auth";
import PageNotFound from "./core/common/pageNotFound";
import AsyncProfilePage from "./core/account/async/asyncProfilePage";
import AsyncParishDetails from "./core/parish/async/asyncParishDetails";
import AsyncParishListing from "./core/parish/async/asyncParishListing";
// import Feedback from "./core/feedback/page/addFeedback";
import AsyncViewAnnouncement from "./core/announcement/async/asyncViewAnnouncement";
import AsyncViewAllAnnouncement from "./core/announcement/async/asyncViewAllAnnouncement";
import AsyncViewAllBulletin from "./core/bulletin/async/asyncViewAllBulletin";
// import ViewPrayer from "./core/prayer/page/viewPrayer";
// import ViewAllPrayer from "./core/prayer/page/viewAllPrayer";
import AccountVerify from "./core/account/page/accountVerify";
// import AsyncUserGuide from "./core/userguide/async/asyncUserGuide";
import AsyncFaq from "./core/faq/async/asyncFaq";
import AsyncMobileApp from "./core/mobileapp/async/asyncMobileApp";
// import ReviewMassBooking from "./core/mass/page/reviewMassBooking";
// import PrayerSetup from "./core/prayer/admin/setupPrayer";
import AsyncPost from "./core/instapost/async/asyncPost";
// import SuperAdminPrayerSetup from "./core/prayer/admin/superAdminSetupPrayer";
// import ArchdiocesePrayerSetup from "./core/prayer/admin/archdioceseSetupPrayer";
// import SuccessMassBooking from "./core/mass/page/successMassBooking";
import AsyncAnnouncementSetup from "./core/announcement/async/asyncAnnouncementSetup";
// import ParishionerProfilePage from "./core/profile/page/parishionerProfile";
// import ViewFeedback from "./core/feedback/admin/viewFeedback";
// import SuperAdminAnnouncementSetup from "./core/announcement/admin/superAdminSetupAnnouncement";
import AsyncArchdioceseAnnouncementSetup from "./core/announcement/async/asyncArchdioceseAnnouncementSetup";
import AsyncAdminDashboard from "./core/admin/async/asyncAdminDashboard";
import AsyncParishionerList from "./core/admin/async/asyncParishionerList";
// import ParishPriest from "./core/blocks/parishPriestPhotoBlock";
// import RegisterCatechesis from "./core/catechesis/page/registerCatechesis";
import CatechismPage from "./core/catechism/page/catechismPage";
import AsyncCatechismAdminPage from "./core/catechism/async/asyncCatechismAdminPage";
import AsyncConfigureCatechism from "./core/config/async/asyncConfigureCatechism";
// import PullCatechesisRegistrations from "./core/catechesis/page/pullRegistrations";
import CatchRouteManager from "./core/catch/deepcast/page/RouteManager";
import CatchAdminMenu from "./core/catch/deepcast/admin/page/AdminMenu";
import AsyncPriest from "./core/priest/async/asyncPriest";
import AsyncRolesSetup from "./core/roles/async/asyncRolesSetup";
import AsyncParishionerDashboard from "./core/admin/async/asyncParishionerDashboard";
// import ConfigureMass from "./core/config/page/configMass";
// import MassParishes from "./core/mass/page/massParishesPage";
// import ComingSoon from "./core/common/comingSoon";
// import MassBookingHistory from "./core/mass/page/massBookingHistory";
import AsyncMassAttendance from "./core/attendance/async/asyncMassAttendance";
import AsyncVerifyParishionerRegistration from "./core/verify/async/asyncVerifyParishionerRegistration";
// import MassBookingsDashboard from "./core/mass/admin/massBookingsDashboard";
// import MassBookingSchedules from "./core/mass/admin/massBookingSchedules";
// import MassBookingSelector from "./core/mass/admin/massBookingSelector";
// import BookedParishionerList from "./core/mass/admin/bookedParishionerList";
import AsyncCheckBranchCode from "./core/branchcode/async/asyncCheckBranchCode";
import AsyncAccountManager from "./core/accountmanager/async/asyncAccountManager";
import AsyncAccountManagerEdit from "./core/accountmanager/async/asyncAccountManagerEdit";
import AsyncAccountManagerView from "./core/accountmanager/async/asyncAccountManagerView";
import AsyncAccountManagerIdEdit from "./core/accountmanager/async/asyncAccountManagerIdEdit";
import AsyncAccountManagerParishEdit from "./core/accountmanager/async/asyncAccountManagerParishEdit";
import AsyncStatsDashboard from "./core/stats/async/asyncStatsDashboard";
// import MassSelector from "./core/mass/page/massSelector";
import AsyncMassIntentionContainer from "./core/massintention/async/asyncMassIntentionContainer";
// import AddMassSchedules from "./core/mass/admin/addMassSchedules";
import AsyncBarcodeScanMassAttendance from "./core/attendance/async/asyncBarcodeScanMassAttendance";
import AsyncAccountManagerEmailEdit from "./core/accountmanager/async/asyncAccountManagerEmailEdit";
// import PreviewDraftMassSchedules from "./core/mass/admin/previewDraftMassSchedules";
import AsyncAccountEdit from "./core/account/async/asyncAccountEdit";
import ParishSetup from "./core/account/page/parishSetup";
import AsyncSupportParishListing from "./core/support/async/asyncSupportParishListing";
import AsyncSupportParishDetails from "./core/support/async/asyncSupportParishDetails";
import AsynParishPriestList from "./core/priest/async/asyncParishPriestList";
// import Updates from "./core/updates/page/updates";
// import IncreaseMass from "./core/config/page/increaseMass";
import ProtectedRoute from "./core/route/protectedRoute";
// import DevotionSchedules from "./core/devotion/page/devotionSchedule";
// import ReviewDevotionBooking from "./core/devotion/page/reviewDevotionBooking";
// import SuccessDevotionBooking from "./core/devotion/page/successDevotionBooking";
import AsyncBookingList from "./core/booking/async/asyncBookingList";
// import QueryParishionerList from "./core/admin/page/queryParishionerList";
// import IncreaseSpecificMass from "./core/config/page/increaseSpecificMass";
// import ConfigureDevotion from "./core/config/page/configDevotion";
// import DevotionBookingsDashboard from "./core/devotion/admin/devotionBookingsDashboard";
// import DevotionBookingSchedules from "./core/devotion/admin/devotionBookingSchedules";
import AsyncUnverifiedID from "./core/unverifiedid/async/asyncUnverifiedID";
// import DevotionSelector from "./core/devotion/page/devotionSelector";
// import DevotionBookingSelector from "./core/devotion/admin/devotionBookingSelector";
import AsyncScanMethod from "./core/attendance/async/asyncScanMethod";
// import AdminBugReport from "./core/report/admin/bugReport";
// import EventSchedules from "./core/mass/page/eventSchedule";
import AsyncAdminStatsDashboard from "./core/stats/async/asyncAdminStatsDashboard";
// import ManualKeyScan from "./core/attendance/page/manualKeyScan";
import AsyncAdminMoreStats from "./core/stats/async/asyncAdminMoreStats";
// import MyStats from "./core/stats/page/myStats";
import AsyncNoShowPage from "./core/stats/async/asyncNoShowPage";
// import GenericEventSchedule from "./core/genericevent/page/genericEventSchedule";
// import ConfigureGenericEvent from "./core/config/page/configGenericEvent";
// import GenericEventBookingReview from "./core/genericevent/page/genericEventBookingReview";
// import GenericEventBookingSuccess from "./core/genericevent/page/genericEventBookingSuccess";
// import AdminGenericEventSchedule from "./core/genericevent/admin/adminGenericEventSchedule";
// import EventBookedParishionerList from "./core/genericevent/component/eventBookedParishionerList";
// import SendEmail from "./core/email/page/sendEmail";
import AsyncViewAllPosts from "./core/instapost/async/asyncViewAllPosts";
import AsyncAccountManagerInvalidAccounts from "./core/accountmanager/async/asyncAccountManagerInvalidAccounts";
// import AsyncCheckSchedule from "./core/admin/async/asyncCheckSchedule";
/** Catholic200SG Festival */
// import AsyncFestivalFAQ from "./core/eventhub/async/asyncFestivalFAQ";
// import Catholic200SGHome from "./core/eventhub/frontend/home/HomePage";
// import IndividualEvent from "./core/eventhub/frontend/individualevent/IndividualEvent";
// import Catholic200SGViewCategory from "./core/eventhub/frontend/category/ViewCategory";
// import EventsAdminDashboard from "./core/eventhub/admin/AdminDashboard";
// import SetupEvents from "./core/eventhub/setup/SetupEvents";
// import AdminEventBookingSchedulesTable from "./core/eventhub/admin/AdminEventBookingSchedulesTable";
// import AdminShowEventDetails from "./core/eventhub/admin/AdminShowEventDetails";
// import AdminShowAllInterested from "./core/eventhub/admin/AdminShowAllInterested";
// import { getFestivalUrl } from "./utils/url";
// import ViewVenue from "./core/eventhub/frontend/viewvenue/page/ViewVenue";
// import Catholic200SGCategory from "./modules/catholic200sg/page/category";
// import Catholic200SGCategorySimple from "./modules/catholic200sg/page/categorySimple";
// import Catholic200SGSubCategorySimple from "./modules/catholic200sg/page/subCategorySimple";
import FinanceDashboard from "./core/finance/admin/FinanceDashboard";
import PayPlusHome from "./core/finance/page/PayPlusHome";
import MyPayPlus from "./core/finance/page/MyPayPlus";
import AsyncAdminEmailPage from "./core/email/async/asyncAdminEmailPage";
import AsyncHopeWallContainer from "./core/wallofhope/async/asyncHopeWallContainer";
import AsyncParishEmailPage from "./core/email/async/asyncParishEmailPage";
// import SynodHome from "./core/synod/SynodHome";
import AsyncSynodDashboard from "./core/synod/async/asyncSynodDashboard";
// import ArchAssemblyHome from "./core/archassembly/ArchAssemblyHome";
// import BarcodeScanAttendance from "./core/attendance/page/barcodeScanAttendance";
// import AdminShowAllBooked from "./core/eventhub/admin/AdminShowAllBooked";
// import AdminShowAllBookingGrouped from "./core/eventhub/admin/AdminShowAllBookingGrouped";
import AsyncMassIntentionDashboardContainer from "./core/massintention/async/asyncMassIntentionDashboardContainer";
import AsyncAdminEventHubDashboard from "./core/eventhub/async/asyncAdminEventHubDashboard";
import AsyncAdminEventHubCreate from "./core/eventhub/async/asyncAdminEventHubCreate";
// import AsyncAccountManagerDeletedBookings from "./core/accountmanager/async/asyncAccountManagerDeletedBookings";
import AsyncEventHubHome from "./core/eventhub/async/asyncEventHubHome";
import AsyncEventHubHomeAll from "./core/eventhub/async/asyncEventHubHomeAll";
import AsyncEventHubHomeOrg from "./core/eventhub/async/asyncEventHubHomeOrg";
import AsyncEventReviewPage from "./core/eventhub/async/asyncEventReviewPage";
import AsyncEventPage from "./core/eventhub/async/asyncEventPage";
import AllEventSchedules from "./core/eventschedules/page/allEventSchedules";
import AsyncAccountCreateUser from "./core/accountmanager/async/asyncAccountCreateUser";
// import EventDashboard from "./core/genericevent/admin/eventDashboard";
// import GenericEventSelector from "./core/genericevent/page/genericEventSelector";
// import AdminGenericEventSelector from "./core/genericevent/admin/adminGenericEventSelector";
import AsyncEventAttendance from "./core/attendance/async/asyncEventAttendance";
// import InterestUpdate from "./core/eventhub/setup/InterestUpdate";
import AsyncAdminEventView from "./core/eventhub/async/asyncAdminEventView";
import AsyncBulletinSetup from "./core/bulletin/async/asyncBulletinSetup";
import AsyncAssemblyAdminRank from "./core/archassembly/async/asyncAssemblyAdminRank";
import AsyncSupportParishDetailsMobile from "./core/support/async/asyncSupportParishDetailsMobile";
import AsyncParishDetailsMobile from "./core/parish/async/asyncParishDetailsMobile";
// import AccountIdEdit from "./core/account/page/accountIdEdit";
// import MassScheduleAdminSimple from "./core/massschedule/admin/MassScheduleAdminSimple";
import LandingPage from "./core/landingpage/page/landingPage";
import PayPlusVendorPage from "./core/finance/page/PayPlusVendorPage";
import CoworkBookingPage from "./core/coworking/page/CoworkBookingPage";
import AsyncCoworkTermsOfUse from "./core/coworking/async/asyncCoworkTermsOfUse";
import AsyncCoworkAdminPanel from "./core/coworking/async/asyncCoworkAdminPanel";
import AsyncCoworkRoomGenerator from "./core/coworking/async/asyncCoworkRoomGenerator";
// import CoworkLandingView from "./core/coworking/views/CoworkLandingView";
// import ReviewRoomBooking from "./core/coworking/views/ReviewRoomBooking";
// import SuccessRoomBooking from "./core/coworking/views/SuccessRoomBooking";
// import RoomSchedules from "./core/coworking/views/RoomSchedules";
import AsyncContributors from "./core/contributors/async/asyncContributors";
import AsyncMainPublicHoliday from "./core/publicholiday/async/asyncMainPublicHoliday";
import AsyncMainOrganisations from "./core/organisation/async/asyncMainOrganisations";
import AsyncMainMassSchedule from "./core/massschedule/async/asyncMainMassSchedule";
import AsyncFormPage from "./core/forms/async/asyncFormPage";
import AsyncFormHome from "./core/forms/async/asyncFormHome";
import AsyncEditFormContainer from "./core/forms/async/asyncEditFormContainer";
import AsyncAdminFormsDashboard from "./core/forms/async/asyncAdminFormsDashboard";
import AsyncAdminFormView from "./core/forms/async/asyncAdminFormView";
// import AdminBookUser from "./core/eventhub/admin/AdminBookUser";
// import MainPapalMassBallot from "./core/papalmassballot/mainPapalMassBallot";
import AsyncMainPriests from "./core/priest/async/asyncMainPriests";
import AsyncMobileAnalytics from "./core/analytics/async/asyncMobileAnalytics";
import AsyncContactUs from "./core/staticpages/async/asyncContactUs";
import AsyncDebugUser from "./core/debug/async/asyncDebugUser";
// import AsyncPFSTicketManager from "./core/pfsticketmanager/async/asyncPFSTicketManager";
// import Balloting from "./core/papalmassballot/admin/Balloting";
// import AsyncMainTicketNotification from "./core/papalmassballot/async/asyncMainTicketNotification";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.archbishop) {
            user.archbishop = idTokenResult.claims.archbishop;
          } else if (idTokenResult.claims.archcomms) {
            user.archcomms = idTokenResult.claims.archcomms;
          } else if (idTokenResult.claims.parishpriest) {
            user.parishpriest = idTokenResult.claims.parishpriest;
          } else if (idTokenResult.claims.secretary) {
            user.secretary = idTokenResult.claims.secretary;
          } else if (idTokenResult.claims.superadmin) {
            user.superadmin = idTokenResult.claims.superadmin;
          } else if (idTokenResult.claims.support) {
            user.support = idTokenResult.claims.support;
          } else if (idTokenResult.claims.systemadmin) {
            user.systemadmin = idTokenResult.claims.systemadmin;
          } else if (idTokenResult.claims.systemvalidator) {
            user.systemvalidator = idTokenResult.claims.systemvalidator;
          } else if (idTokenResult.claims.apiconsumer) {
            user.apiconsumer = idTokenResult.claims.apiconsumer;
          } else if (idTokenResult.claims.finance) {
            user.finance = idTokenResult.claims.finance;
          } else if (idTokenResult.claims.catchadmin) {
            user.catchadmin = idTokenResult.claims.catchadmin;
          } else if (idTokenResult.claims.catchparishadmin) {
            user.catchparishadmin = idTokenResult.claims.catchparishadmin;
          } else if (idTokenResult.claims.festivaladmin) {
            user.festivaladmin = idTokenResult.claims.festivaladmin;
          } else if (idTokenResult.claims.synodadmin) {
            user.synodadmin = idTokenResult.claims.synodadmin;
          } else if (idTokenResult.claims.festivalscanner) {
            user.festivalscanner = idTokenResult.claims.festivalscanner;
          } else if (idTokenResult.claims.eventhubadmin) {
            user.eventhubadmin = idTokenResult.claims.eventhubadmin;
          } else if (idTokenResult.claims.genericadmin) {
            user.genericadmin = idTokenResult.claims.genericadmin;
          }
          // getCurrentUserData(user.uid).then((userDataRes) => {
          //   console.log("**************** QUERY USER");
          //   this.setState({
          //     user: {
          //       ...user,
          //       ...userDataRes,
          //     },
          //     loading: false,
          //   });
          // });
          attachUserDataListener(user.uid, (userDataRes) =>
            this.setState({
              user: {
                ...user,
                ...userDataRes,
              },
              loading: false,
            })
          );
        });
      } else {
        this.setState({ user: null, loading: false });
      }
    });
  }

  render() {
    const { user, loading } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        <AppGlobalContext.Provider value={{ user }}>
          <Switch>
            {/*
          NO LOGIN REQUIRED
          */}
            {/* <Route
              path="/catholic200sg/feedback/children"
              component={() => {
                window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLSdlM1QgB1NbyMB7O3Eh-O_ZE2GzFx5t28P-hWlfulg_SEM4lw/viewform";
                return null;
              }}
            /> */}
            {/* <Route
              path="/catholic200sg/feedback/youth"
              component={() => {
                window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLScrX7ePYp_P0vLcz3HOwWMUWij2hbmkDPAzd7istui6LThLxA/viewform";
                return null;
              }}
            /> */}
            {/* <Route
              path="/catholic200sg/feedback/group"
              component={() => {
                window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLSf0675K3kYRUulNb2DsgVZBS3EHqM3SNMm_Ik-r2jkkEmMB6w/viewform";
                return null;
              }}
            /> */}
            {/* <Route
              path="/catholic200sg/feedback"
              component={() => {
                window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLSc9ZzqL5H3nqBaCTYByWvraqMbbsM9nHLLHACBbnYvluooAhA/viewform";
                return null;
              }}
            /> */}
            {/* <Route
              path="/catholic200sg"
              component={() => {
                window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLSdgNJfUvv636JqSKZbJy2hXEch4_ZobDP4tK-dp40SI2c-5bg/viewform";
                return null;
              }}
            /> */}
            <Route
              path="/contactus"
              render={(props) => {
                return <AsyncContactUs {...props} user={user} />;
              }}
            />
            {/* <Route
              path="/help"
              render={(props) => {
                return <AsyncUserGuide {...props} user={user} />;
              }}
            /> */}
            {/* <Route
              path="/previewschedules/:parishId"
              render={(props) => {
                return (
                  <AllEventSchedules
                    {...props}
                    user={user}
                    uri="previewschedules"
                  />
                );
              }}
            /> */}
            {/* <Route
              path="/previewschedules"
              render={(props) => {
                return (
                  <AllEventSchedules
                    {...props}
                    user={user}
                    uri="previewschedules"
                  />
                );
              }}
            /> */}
            <Route
              path="/schedules/:parishId"
              render={(props) => {
                return (
                  <AllEventSchedules {...props} user={user} uri="schedules" />
                );
              }}
            />
            {/* <Route
              path="/papalmass"
              render={(props) => {
                return <MainPapalMassBallot {...props} />;
              }}
            /> */}
            {/* <ProtectedRoute
              path="/papalmassballoting"
              user={user}
              component={Balloting}
            /> */}
            {/* <ProtectedRoute
              path="/commandcenter/papalticketnotification"
              user={user}
              component={AsyncMainTicketNotification}
            /> */}
            <Route
              path="/schedules"
              render={(props) => {
                return (
                  <AllEventSchedules {...props} user={user} uri="schedules" />
                );
              }}
            />
            <Route
              path="/mass-schedules/:parishId"
              render={(props) => {
                return (
                  <AllEventSchedules {...props} user={user} uri="schedules" />
                );
              }}
            />
            <Route
              path="/mass-schedules"
              render={(props) => {
                return (
                  <AllEventSchedules {...props} user={user} uri="schedules" />
                );
              }}
            />
            <Route
              path="/schedules/:parishId"
              render={(props) => {
                return (
                  <AllEventSchedules {...props} user={user} uri="schedules" />
                );
              }}
            />
            <Route
              path="/schedules"
              render={(props) => {
                return (
                  <AllEventSchedules {...props} user={user} uri="schedules" />
                );
              }}
            />
            <Route
              path="/faq"
              render={(props) => {
                return <AsyncFaq {...props} user={user} />;
              }}
            />
            <Route
              path="/app"
              render={(props) => {
                return <AsyncMobileApp {...props} user={user} />;
              }}
            />
            {/* <Route
              path="/updates"
              render={(props) => {
                return <Updates {...props} user={user} />;
              }}
            /> */}
            <Route
              path="/contributors"
              render={(props) => {
                return <AsyncContributors {...props} user={user} />;
              }}
            />
            <Route
              path="/schedule"
              render={(props) => {
                return (
                  <AllEventSchedules {...props} user={user} uri="schedules" />
                );
              }}
            />
            {/* <Route
            path="*"
            render={(props) => {
              if (!user) {
                return <ComingSoon id={rand} />;
              }
              return <ComingSoon id={rand} {...props} user={user} />;
            }}
          />
          <Redirect from="/" to="/home" /> */}
            <Route
              path="/auth"
              render={(props) => {
                return <AuthHandler {...props} user={user} />;
              }}
            />
            <Route
              path="/register"
              render={(props) => {
                // if (user) return <Redirect to="/home" />;
                return <RegisterForm {...props} user={user} />;
              }}
            />
            <Route
              path="/forgot"
              render={(props) => {
                if (user) {
                  return <Redirect to="/home" />;
                }
                return <ForgotPassword {...props} user={user} />;
              }}
            />
            <Route
              path="/login"
              render={(props) => {
                if (user) {
                  const routerState = props.location.state;
                  // Handles post-login reroutes
                  if (routerState && routerState.pathname === "/synodlogin") {
                    return (
                      <Redirect
                        to={{ pathname: "/synod", search: routerState.search }}
                      />
                    );
                  } else {
                    return <Redirect to="/home" />;
                  }
                }
                return <LoginForm {...props} user={user} />;
              }}
            />
            <Route
              path="/apc"
              render={(props) => {
                return (
                  <AsyncHopeWallContainer
                    {...props}
                    wallName={"apc"}
                    user={user}
                  />
                );
              }}
            />
            <Route
              path="/blessings"
              render={(props) => {
                return (
                  <AsyncHopeWallContainer
                    {...props}
                    wallName={"blessings"}
                    user={user}
                  />
                );
              }}
            />
            <Route
              path="/hope/:eventName"
              render={(props) => {
                return <AsyncHopeWallContainer {...props} user={user} />;
              }}
            />
            <Route
              path="/commune/termsofuse"
              render={(props) => {
                return <AsyncCoworkTermsOfUse {...props} user={user} />;
              }}
            />
            <Route
              path="/welcome"
              render={(props) => {
                if (user) {
                  return <Redirect to="/home" />;
                }
                return <LandingPage {...props} user={user} />;
              }}
            />
            <Route
              path="/support/:parish"
              render={(props) => {
                return <AsyncSupportParishDetails {...props} user={user} />;
              }}
            />
            <Route
              path="/support"
              render={(props) => {
                return <AsyncSupportParishListing {...props} user={user} />;
              }}
            />
            <ProtectedRoute
              path="/commune"
              user={user}
              component={CoworkBookingPage}
            />
            <ProtectedRoute
              path="/commandcenter/commune"
              user={user}
              component={AsyncCoworkAdminPanel}
            />
            <ProtectedRoute
              path="/commandcenter/communegenerator"
              user={user}
              component={AsyncCoworkRoomGenerator}
            />
            {/* <ProtectedRoute
              path="/assembly"
              user={user}
              component={ArchAssemblyHome}
            /> */}
            {/*<Route
              path="/synod"
              render={(props) => {
                return <SynodHome {...props} user={user} />;
              }}
            />
            <ProtectedRoute
              path="/synodlogin"
              render={(props) => {
                return <SynodHome {...props} user={user} />;
              }}
            /> */}
            <ProtectedRoute
              path="/commandcenter/appanalytics"
              user={user}
              component={AsyncMobileAnalytics}
            />
            <ProtectedRoute
              path="/commandcenter/assembly/rank"
              user={user}
              component={AsyncAssemblyAdminRank}
            />
            <ProtectedRoute
              path="/commandcenter/synod"
              user={user}
              component={AsyncSynodDashboard}
            />
            <ProtectedRoute
              component={AsyncEventAttendance}
              path={`/eventattendance/:code`}
              user={user}
            />
            <Route
              path="/events/:id/review"
              render={(props) => {
                return <AsyncEventReviewPage {...props} />;
              }}
            />
            <Route
              path="/events/all"
              render={(props) => {
                return <AsyncEventHubHomeAll {...props} />;
              }}
            />
            <Route
              path="/events/org/:orgLink"
              render={(props) => {
                return <AsyncEventHubHomeOrg {...props} />;
              }}
            />
            <Route
              path="/events/:id"
              render={(props) => {
                return <AsyncEventPage {...props} />;
              }}
            />
            <Route
              path="/events"
              render={(props) => {
                return <AsyncEventHubHome {...props} />;
              }}
            />
            <Route
              path="/parish/:parish"
              render={(props) => {
                return <AsyncParishDetails {...props} user={user} />;
              }}
            />
            <Route
              path="/parish"
              render={(props) => {
                return <AsyncParishListing {...props} user={user} />;
              }}
            />
            <Route
              path="/priest/:parish"
              render={(props) => {
                return <AsyncPriest {...props} user={user} />;
              }}
            />
            <Route
              path="/priest"
              render={(props) => {
                return <AsynParishPriestList {...props} user={user} />;
              }}
            />
            <Route
              path="/m/support/:parish"
              render={(props) => {
                return (
                  <AsyncSupportParishDetailsMobile {...props} user={user} />
                );
              }}
            />
            <Route
              path="/m/parish/:parish"
              render={(props) => {
                return <AsyncParishDetailsMobile {...props} user={user} />;
              }}
            />
            <Route
              path="/bulletin/:parishId"
              render={(props) => {
                return <AsyncViewAllBulletin {...props} user={user} />;
              }}
            />
            <Route
              path="/bulletin"
              render={(props) => {
                return <AsyncViewAllBulletin {...props} user={user} />;
              }}
            />
            <Route
              path="/forms/:formId"
              render={(props) => {
                return <AsyncFormPage {...props} user={user} />;
              }}
            />
            <ProtectedRoute
              path="/forms"
              user={user}
              component={AsyncFormHome}
            />
            <ProtectedRoute
              path="/commandcenter/events/create"
              user={user}
              component={AsyncAdminEventHubCreate}
            />
            <ProtectedRoute
              path="/commandcenter/events/:id/edit"
              user={user}
              component={AsyncAdminEventHubCreate}
            />
            <ProtectedRoute
              path="/commandcenter/events/:id/view"
              user={user}
              component={AsyncAdminEventView}
            />
            <ProtectedRoute
              path="/commandcenter/events"
              user={user}
              component={AsyncAdminEventHubDashboard}
            />
            <ProtectedRoute
              component={AsyncMassIntentionDashboardContainer}
              path="/commandcenter/massintentions"
              user={user}
            />
            {/* <ProtectedRoute component={Feedback} path="/feedback" user={user} /> */}
            {/* Start of Catholic200SG Festival routes */}
            {/* <ProtectedRoute
              component={Catholic200SGFAQ}
              path="/festival/faq/:id"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={AsyncFestivalFAQ}
              path="/festival/faq"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={Catholic200SGViewCategory}
              path="/festival/s/:category"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={EventsAdminDashboard}
              path="/commandcenter/festival"
              user={user}
            />
            <ProtectedRoute
              component={SetupEvents}
              path="/commandcenter/setup/festival"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={AdminShowEventDetails}
              path="/commandcenter/eventbookings/festival/view/:id"
              user={user}
            />
            <ProtectedRoute
              component={AdminShowAllInterested}
              path="/commandcenter/eventbookings/festival/showallinterested"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={AdminShowAllBooked}
              path="/commandcenter/eventbookings/festival/showallbooked"
              user={user}
            />
            <ProtectedRoute
              component={AdminShowAllBookingGrouped}
              path="/commandcenter/eventbookings/festival/showallbookinggroup"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={AdminEventBookingSchedulesTable}
              path="/commandcenter/eventbookings/festival"
              user={user}
            /> */}
            {/* <ProtectedRoute
            component={Catholic200SGSubCategorySimple}
            path="/festival/:category/:subcategory"
            user={user}
          />
          <ProtectedRoute
            component={Catholic200SGCategorySimple}
            path="/festival/:category"
            user={user}
          /> */}
            {/* <ProtectedRoute
              component={BarcodeScanAttendance}
              path={`${getFestivalUrl()}/attendance`}
              user={user}
            />
            <ProtectedRoute
              component={Catholic200SGHome}
              path={getFestivalUrl()}
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={ViewVenue}
              path={`${getFestivalUrl()}/view/venue`}
              user={user}
            />
            */}
            {/* <ProtectedRoute
            component={IndividualEvent}
            path="/festival-p1"
            user={user}
          /> */}
            {/* <ProtectedRoute
            component={Catholic200SGCategory}
            path="/catholic200festival/:category"
            user={user}
          /> */}
            {/* <ProtectedRoute
            component={Catholic200SGHome}
            path="/catholic200festival"
            user={user}
          /> */}
            {/* End of Catholic200SG Festival routes */}
            {/* <ProtectedRoute
              component={MassBookingHistory}
              path="/mymassbookings"
              user={user}
            /> */}
            <ProtectedRoute
              component={AsyncBookingList}
              path="/mybookings"
              user={user}
            />
            {/* <ProtectedRoute component={MyStats} path="/mystats" user={user} /> */}
            <ProtectedRoute
              component={AsyncScanMethod}
              path="/attendance"
              user={user}
            />
            <Redirect from="/v2/attendance" to="/camerascan" />
            <Redirect from="/v3/attendance" to="/camerascan" />
            <ProtectedRoute
              component={AsyncMassAttendance}
              path="/camerascan"
              user={user}
            />
            <Redirect from="/scan" to="/barcodescan" />
            <Redirect from="/v2/scan" to="/barcodescan" />
            <Redirect from="/v3/scan" to="/barcodescan" />
            <ProtectedRoute
              component={AsyncBarcodeScanMassAttendance}
              path="/barcodescan"
              user={user}
            />
            {/* <ProtectedRoute
              component={ManualKeyScan}
              path="/manualscan"
              user={user}
            /> */}

            <ProtectedRoute
              component={AsyncCheckBranchCode}
              path="/checkbranchcode/:direction"
              user={user}
            />
            <ProtectedRoute
              component={AsyncVerifyParishionerRegistration}
              path="/verify"
              user={user}
            />
            {/* START Mass Bookings */}
            {/* <ProtectedRoute
              component={MassSelector}
              path="/event/mass/selector/:parish"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={SuccessMassBooking}
              path="/event/mass/booking/success/:year/:month/:schedule"
              user={user}
            /> */}
            {/* <ProtectedRoute
            component={DeclarationPage}
            path="/event/mass/:parish/review/:year/:month/:schedule/:id"
            user={user}
          /> */}
            {/* <ProtectedRoute
              component={ReviewMassBooking}
              path="/event/mass/:parish/review/:year/:month/:schedule/:id"
              user={user}
            />
            <ProtectedRoute
              component={ReviewMassBooking}
              path="/event/mass/:parish/review/:year/:month/:schedule/"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={EventSchedules}
              path="/event/mass/:parish/:year/:month"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={GenericEventSelector}
              path="/event/:event/selector/:parish"
              user={user}
            />
            <ProtectedRoute
              component={GenericEventBookingSuccess}
              path="/event/:event/booking/success/:year/:month/:schedule"
              user={user}
            />
            <ProtectedRoute
              component={GenericEventBookingReview}
              path="/event/:event/:parish/review/:year/:month/:schedule/:id"
              user={user}
            />
            <ProtectedRoute
              component={GenericEventBookingReview}
              path="/event/:event/:parish/review/:year/:month/:schedule"
              user={user}
            />
            <ProtectedRoute
              component={GenericEventSchedule}
              path="/event/:event/:parish/:year/:month"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={EventDashboard}
              path="/commandcenter/eventdashboard"
              user={user}
            /> */}
            {/* END Mass Bookings */}
            <ProtectedRoute
              // component={MaintenancePage}
              component={AsyncMassIntentionContainer}
              path="/massintentions"
              user={user}
            />
            {/* <ProtectedRoute
              component={DevotionSelector}
              path="/devotion/selector/:parish"
              user={user}
            />
            <ProtectedRoute
              component={SuccessDevotionBooking}
              path="/devotion/booking/success/:year/:month/:schedule"
              user={user}
            />
            <ProtectedRoute
              component={ReviewDevotionBooking}
              path="/devotion/:parish/review/:year/:month/:schedule/:id"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={ReviewDevotionBooking}
              path="/devotion/:parish/review/:year/:month/:schedule"
              user={user}
            />
            <ProtectedRoute
              component={DevotionSchedules}
              path="/devotion/:parish/:year/:month"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={MassParishes}
              path="/masses"
              user={user}
            /> */}

            <ProtectedRoute
              component={MyPayPlus}
              path="/mypayplus"
              user={user}
            />
            <ProtectedRoute
              component={PayPlusVendorPage}
              path="/payplus/vendor"
              user={user}
            />
            <ProtectedRoute
              component={PayPlusHome}
              path="/payplus"
              user={user}
            />
            {/* <ProtectedRoute
              component={RegisterCatechesis}
              path="/catechesis"
              user={user}
            /> */}
            <ProtectedRoute
              component={CatechismPage}
              path="/catechism"
              user={user}
            />
            <ProtectedRoute
              component={AsyncCatechismAdminPage}
              path="/commandcenter/catechism"
              user={user}
            />
            <ProtectedRoute
              component={CatchRouteManager}
              path="/catch"
              user={user}
            />
            <ProtectedRoute
              component={CatchAdminMenu}
              path="/commandcenter/catch"
              user={user}
            />
            {/* <ProtectedRoute
              component={PullCatechesisRegistrations}
              path="/commandcenter/catechesisregistrations"
              user={user}
            /> */}
            <ProtectedRoute
              component={ParishSetup}
              path="/account/parish/edit"
              user={user}
            />
            <Route
              path="/account/created"
              render={(props) => {
                if (!user) {
                  return <Redirect to="/login" />;
                }
                return <RegisterSuccess {...props} user={user} />;
              }}
            />
            <ProtectedRoute
              component={AccountSetup}
              path="/account/setup"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAccountEdit}
              path="/account/edit"
              user={user}
            />
            {/* <ProtectedRoute
            component={AccountIdEdit}
            path="/account/idedit"
            user={user}
          /> */}
            <Route
              path="/account/verify"
              render={(props) => {
                if (!user) {
                  return <Redirect to="/login" />;
                }
                return <AccountVerify {...props} user={user} />;
              }}
            />
            <ProtectedRoute
              component={AccountSetupSuccess}
              path="/account/success"
              user={user}
            />
            <ProtectedRoute
              component={AsyncProfilePage}
              path="/profile"
              user={user}
            />
            {/* <ProtectedRoute
            component={ParishionerProfilePage}
            path="/profile/:id"
            user={user}
          /> */}
            {/* <ProtectedRoute
              component={ConfigureMass}
              path="/commandcenter/configure/mass"
              user={user}
            /> */}
            <ProtectedRoute
              component={AsyncConfigureCatechism}
              path="/commandcenter/configure/catechism"
              user={user}
            />
            {/* <ProtectedRoute
              component={InterestUpdate}
              path="/commandcenter/setup/interest"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={SendEmail}
              path="/commandcenter/send/email"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={ConfigureDevotion}
              path="/commandcenter/configure/devotion"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={ConfigureGenericEvent}
              path="/commandcenter/configure/genericevent"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={IncreaseMass}
              path="/commandcenter/increase/mass"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={SuperAdminAnnouncementSetup}
              path="/commandcenter/announcement/suadmin"
              user={user}
            /> */}
            <ProtectedRoute
              component={AsyncArchdioceseAnnouncementSetup}
              path="/commandcenter/announcement/archadmin"
              user={user}
            />
            <ProtectedRoute
              component={AsyncDebugUser}
              path="/commandcenter/debuguser"
              user={user}
            />
            <ProtectedRoute
              component={AsyncViewAnnouncement}
              path="/announcement/view/:announcementid"
              user={user}
            />
            <ProtectedRoute
              component={AsyncViewAllAnnouncement}
              path="/announcement"
              user={user}
            />
            <ProtectedRoute
              component={AsyncBulletinSetup}
              path="/commandcenter/bulletin"
              user={user}
            />
            <ProtectedRoute
              component={AsyncViewAllPosts}
              path="/posts"
              user={user}
            />
            <ProtectedRoute
              component={AsyncRolesSetup}
              path="/commandcenter/roles"
              user={user}
            />
            <ProtectedRoute
              component={AsyncMainPublicHoliday}
              path="/commandcenter/publicholiday"
              user={user}
            />
            <ProtectedRoute
              component={AsyncMainOrganisations}
              path="/commandcenter/orgs"
              user={user}
            />
            <ProtectedRoute
              component={AsyncMainPriests}
              path="/commandcenter/priests"
              user={user}
            />
            {/* <ProtectedRoute
              component={PreviewDraftMassSchedules}
              path="/commandcenter/preview/massbookings/:parish"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={AddMassSchedules}
              path="/commandcenter/massbookings/add"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={MassBookingSelector}
              path="/massbookings/selector/:parish"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={MassBookingSchedules}
              path="/massbookings/:parish/:year/:month"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={MassScheduleAdminSimple}
              path="/commandcenter/massschedule/:parishParam"
              user={user}
            /> */}
            <ProtectedRoute
              component={AsyncMainMassSchedule}
              path="/commandcenter/massschedule/:parishParam"
              user={user}
            />
            {/* <ProtectedRoute
              component={DevotionBookingSelector}
              path="/devotionbookings/selector/:parish"
              user={user}
            />
            <ProtectedRoute
              component={DevotionBookingSchedules}
              path="/devotionbookings/:parish/:year/:month"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={AdminGenericEventSchedule}
              path="/admin/:event/:parish/:year/:month"
              user={user}
            />
            <ProtectedRoute
              component={AdminGenericEventSelector}
              path="/admin/:event/selector/:parish"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={BookedParishionerList}
              path="/massbookings/:parishId/:massId"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={BookedParishionerList}
              path="/devotionbookings/:parishId/:massId"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={EventBookedParishionerList}
              path="/eventbookings/:parishId/:massId"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={MassBookingsDashboard}
              path="/commandcenter/massbookings/dashboard"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={AsyncCheckSchedule}
              path="/commandcenter/checkschedule"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={DevotionBookingsDashboard}
              path="/commandcenter/devotionbookings/dashboard"
              user={user}
            /> */}
            <ProtectedRoute
              component={AsyncParishionerDashboard}
              path="/commandcenter/parishioner/dashboard"
              user={user}
            />
            <ProtectedRoute
              component={AsyncParishionerList}
              path="/commandcenter/parishioners/:parishId"
              user={user}
            />
            <ProtectedRoute
              component={AsyncParishionerList}
              path="/commandcenter/parishioners"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAnnouncementSetup}
              path="/commandcenter/announcement"
              user={user}
            />
            <ProtectedRoute
              component={AsyncPost}
              path="/commandcenter/post"
              user={user}
            />
            <ProtectedRoute
              component={AsyncStatsDashboard}
              path="/commandcenter/stats"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAdminStatsDashboard}
              path="/commandcenter/adminstats"
              user={user}
            />
            <ProtectedRoute
              component={AsyncNoShowPage}
              path="/commandcenter/adminmorestats/:parishId/:date"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAdminMoreStats}
              path="/commandcenter/adminmorestats/:parishId"
              user={user}
            />
            {/* <ProtectedRoute
              component={QueryParishionerList}
              path="/commandcenter/special"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={IncreaseSpecificMass}
              path="/commandcenter/increasefew"
              user={user}
            /> */}
            {/* <ProtectedRoute
              component={ViewFeedback}
              path="/commandcenter/feedback"
              user={user}
            /> */}
            <ProtectedRoute
              component={AsyncAccountManagerInvalidAccounts}
              path="/commandcenter/accountmanager/invalidaccount"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAccountManagerView}
              path="/commandcenter/accountmanager/view/:parishionerid"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAccountManagerIdEdit}
              path="/commandcenter/accountmanager/idedit/:parishionerid"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAccountManagerEmailEdit}
              path="/commandcenter/accountmanager/emailedit/:parishionerid"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAccountManagerParishEdit}
              path="/commandcenter/accountmanager/parishedit/:parishionerid"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAccountManagerEdit}
              path="/commandcenter/accountmanager/edit/:parishionerid"
              user={user}
            />
            {/* <ProtectedRoute
              component={AdminBookUser}
              path="/commandcenter/accountmanager/bookuser/:id"
              user={user}
            /> */}
            <ProtectedRoute
              component={AsyncAccountManager}
              path="/commandcenter/accountmanager"
              user={user}
            />
            <ProtectedRoute
              component={AsyncEditFormContainer}
              path="/commandcenter/forms/create"
              user={user}
            />
            <ProtectedRoute
              component={AsyncEditFormContainer}
              path="/commandcenter/forms/:formId/edit"
              user={user}
            />
            <ProtectedRoute
              path="/commandcenter/forms/:formId/view"
              user={user}
              component={AsyncAdminFormView}
            />
            <ProtectedRoute
              component={AsyncAdminFormsDashboard}
              path="/commandcenter/forms"
              user={user}
            />
            <ProtectedRoute
              component={AsyncUnverifiedID}
              path="/commandcenter/unverifiedid"
              user={user}
            />
            {/* <ProtectedRoute
              component={AdminBugReport}
              path="/commandcenter/bugreports"
              user={user}
            /> */}
            <ProtectedRoute
              component={AsyncParishEmailPage}
              path="/commandcenter/email"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAdminEmailPage}
              path="/commandcenter/adminemail"
              user={user}
            />
            <ProtectedRoute
              component={AsyncAccountCreateUser}
              path="/commandcenter/createuser"
              user={user}
            />
            {/* <ProtectedRoute
              component={AsyncPFSTicketManager}
              path="/commandcenter/pfsticket"
              user={user}
            /> */}
            <ProtectedRoute
              component={AsyncAdminDashboard}
              path="/commandcenter"
              user={user}
            />
            <ProtectedRoute
              component={FinanceDashboard}
              path="/finance"
              user={user}
            />
            <ProtectedRoute component={HomePage} path="/home" user={user} />
            <Redirect from="/" exact to="/home" />
            <Route
              path="*"
              render={(props) => {
                if (!user) {
                  return <PageNotFound />;
                }
                return <PageNotFound {...props} user={user} />;
              }}
            />
            <Route
              path="/404"
              render={(props) => {
                if (!user) {
                  return <PageNotFound />;
                }
                return <PageNotFound {...props} user={user} />;
              }}
            />
          </Switch>
          {(window?.location?.pathname ?? "").substring(0, 9) !== "/finance" &&
            (window?.location?.pathname ?? "").substring(0, 5) !== "/hope" &&
            (window?.location?.pathname ?? "").substring(0, 8) !==
              "/commune" && <div className="clearfix my-4">&nbsp;</div>}
        </AppGlobalContext.Provider>
      </React.Fragment>
    );
  }
}

export default App;
