import React, { Component } from "react";
import Header from "../../common/header";
import { getAllParishes } from "../services/allparishes";
import DisplaySchedules from "../components/displaySchedules";
import ScrollToTop from "../../common/scrollToTop";
import {
  getParishByCode,
  getParishes,
  getParishLinkById,
  getParishNameById,
} from "../../../services/parish";
import { logEvent } from "../../../services/log";
import { Modal } from "react-bootstrap";
import ParishMainPanel from "../../parish/parishMainPanel";
import PriestCard from "../../priest/components/priestCard";
import InterpretSchedule from "../components/interpretSchedule";
import { getSchedulesFromDB } from "../utils/utils";
import { getAllChurchesSchedules } from "../services/schedules";
import SwipableButtonFilter from "../../common/swipableButtonFilter";
import ReloadModal from "../../common/reloadModal";
import LineParishIconShortcuts from "../../common/lineParishIconShortcuts";
// import CFBanner from "../../papalmassballot/component/cfBanner";
// import ButtonList from "../../common/buttonList";
// import NotificationBox from "../../common/NotificationBox";

class AllEventSchedules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showParishModal: false,
      filtered: [],
      filter: "Show All",
      upcoming: [],
      options: [],
      schedule: null,
      selection: "",
      parishlist: getParishes(),
      parishlink: "cathedral",
      parishobj: null,
      iconobj: null,
      user: this.props.user,
      uri: this.props.uri || "massschedule",
      results: [],
      loading: true,
    };
  }

  openParishModal = (parish) => {
    const [obj] = this.state.parishlist.filter((i) => i.name === parish);
    this.setState({
      parishobj: obj,
      showParishModal: true,
      parishlink: obj.link,
    });

    logEvent(`view_sched_open_${obj.link}`, {
      source: "webschedule",
    });
  };

  async componentDidMount() {
    const parishCode = this.props.match.params.parishId;
    const selections = getAllParishes();
    this.setState({ loading: true });

    let selection = "Cathedral of the Good Shepherd";
    let parishlink = "cathedral";
    if (this.state.user) {
      const parish = getParishNameById(this.state.user.parish || "1");
      parishlink = getParishLinkById(this.state.user.parish || "1");
      selection = parish;
    } else {
      const parish = getParishByCode(parishCode || parishlink);
      if (parish) {
        selection = parish.name;
        parishlink = parish.link;
      } else {
        parishlink = "cathedral";
      }
    }
    const [parishObj] = this.state.parishlist.filter(
      (i) => i.name === selection
    );

    this.props.history.replace(`/${this.props.uri}/${parishlink}`);
    const [obj, type] = await getSchedulesFromDB(parishlink);
    type.sort();
    this.setState({
      parishlink,
      parishobj: parishObj,
      iconobj: {
        address: parishObj?.address,
        bulletin: `/bulletin/${parishlink}`,
        more: () => this.openParishModal(selection),
      },
      schedule: obj,
      filtered: obj,
      selection: selection,
      options: selections,
      loading: false,
      types: type.length > 0 ? ["Show All", ...type] : [],
    });
    logEvent("view_sched", {
      source: "webschedule",
    });
    window.scrollTo(0, 0);
  }

  selectHandler = async (option) => {
    const input = option || "Cathedral of the Good Shepherd";
    let parishlink = "cathedral";
    let baseurl = `/${this.props.uri}`;
    let schedules = null;
    let type = null;
    let parishObj = null;

    if (input !== "All Churches") {
      //selected valid entry
      const [parish] = this.state.parishlist.filter((i) => i.name === input);
      baseurl = `${baseurl}/${parish.link}`;
      parishlink = parish.link;
      [schedules, type] = await getSchedulesFromDB(parishlink);
      type.sort();
      type = type.length > 0 ? ["Show All", ...type] : [];
      [parishObj] = this.state.parishlist.filter((i) => i.name === input);
    } else {
      //loading

      this.setState({ loading: true, selection: input });
      //get all schedules
      [schedules, type] = await getAllChurchesSchedules(
        this.state.parishlist,
        false
      );
      type.sort();
      type = type.length > 0 ? ["Show All", ...new Set(type)] : [];
      schedules.sort((a, b) => a.start - b.start);
      //clear loading
      this.setState({ loading: false });
    }
    logEvent(
      `view_sched_sel_${input
        .replace(/ /g, "")
        .replace(/'/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .toLowerCase()}`,
      {
        source: "webschedule",
      }
    );
    this.props.history.replace(baseurl);

    this.setState({
      parishobj: parishObj,
      iconobj: {
        address: parishObj?.address,
        bulletin: `/bulletin/${parishlink}`,
        more: () => this.openParishModal(input),
      },
      schedule: schedules,
      filtered: schedules,
      parishlink,
      types: type,
      filter: "Show All",
      // filtered,
      selection: input,
      loading: false,
    });
  };

  filterSelection = (value) => {
    if (value === "Show All") {
      this.setState({ filter: value, filtered: this.state.schedule });
    } else {
      this.setState({
        filter: value,
        filtered: this.state.schedule.filter((i) => i.type === value),
      });
    }
  };

  closeParishModal = () => {
    this.setState({ showParishModal: false, parishlink: "" });
  };

  ParishDetails = () => {
    if (!this.state.parishobj) {
      return null;
    }
    return (
      <Modal
        show={this.state.showParishModal}
        onHide={this.closeParishModal}
        animation={true}
        centered
        // backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark font-weight-bold">
            {this.state.parishobj.completename}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <PriestCard
            border={false}
            key={this.state.parishobj._id}
            details={this.state.parishobj}
            link={this.state.parishobj.link}
            label="Select"
          />
          <ParishMainPanel
            parish={this.state.parishobj}
            hidebutton={true}
            priestHandler={() => {}}
          />
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.props.user ? (
          <Header type="backandlogo" />
        ) : (
          <Header hide="profile" type="backandlogo" />
        )}
        <ReloadModal />

        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6 px-1">
            <main className="container">
              <div className="pb-3 pt-3">
                <h3 className="text-left">Schedules</h3>

                <DisplaySchedules
                  data={this.state.filtered}
                  options={this.state.options}
                  selectHandler={this.selectHandler}
                  selection={this.state.selection}
                  mode={this.state.mode}
                  parishlist={this.state.parishlist}
                />
                {/* <div className="pb-2">
                  <CFBanner code="sched" />
                </div> */}
                {this.state.loading === true && (
                  <div className="p-4 mx-4 mt-4 mb-4 text-center">
                    <div
                      className={`spinner-border spinner-border-lg text-danger`}
                      role="status"
                      aria-hidden="true"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p className="py-2 text-muted">Loading...</p>
                  </div>
                )}

                {this.state.selection !== "All Churches" &&
                  this.state.loading === false && (
                    <div className="pb-3">
                      {this.state.iconobj && (
                        <div className="pt-2">
                          <LineParishIconShortcuts item={this.state.iconobj} />
                        </div>
                      )}
                    </div>
                  )}
                {this.state.loading === false && this.state.filtered && (
                  <div className="">
                    {this.state.selection?.length > 0 && (
                      <div>
                        <SwipableButtonFilter
                          selected={this.state.filter}
                          entries={this.state.types}
                          selectHandler={(value) => this.filterSelection(value)}
                        />
                        {/* <NotificationBox /> */}
                        <InterpretSchedule
                          date={new Date()}
                          days={40}
                          showall={false}
                          selection={this.state.selection}
                          schedule={this.state.filtered}
                          clickHandler={(church) =>
                            this.openParishModal(church)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}

                {this.state.loading === false && (
                  <>
                    <this.ParishDetails />
                    <ScrollToTop />
                  </>
                )}
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AllEventSchedules;
