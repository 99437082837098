import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";

import AppGlobalContext from "../../../../AppGlobalContext";
import withLayout from "../components/Layout";
import { useActiveRegistration } from "../hooks/hooks";

import SpinnerText from "../../../common/spinnerwithText";

import { registrationCollectionName } from "../services/collections";
import {
  getPaymentStatus,
  requestWaiver,
  makeSuccessfulPayment,
} from "../services/payments";
import fire from "../../../../services/fire";
import GenQRPayment from "../../../finance/component/GenQRPayment";
import { useParishesConfig } from "../hooks/hooks";
import { Card } from "react-bootstrap";

import { levelIdToName } from "../services/levels";

function ClassFund() {
  const { user } = useContext(AppGlobalContext);

  const parishesConfig = useParishesConfig();
  const activeRegistrationState = useActiveRegistration();

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);

  const [waiverReason, setWaiverReason] = useState("");

  const [paymentStage, setPaymentStage] = useState(0);
  const [payplusCampaign, setPayplusCampaign] = useState({
    id: "",
    amount: -1,
  });

  const registrationDatum = activeRegistrationState.data;

  const { selectedParishId: parishId } = registrationDatum;

  const parish = parishesConfig.find(({ id }) => id === parishId);

  const selectedParishesConfig = parishesConfig.find(
    (cfg) => cfg.id === parishId
  );
  const programme = selectedParishesConfig.programmes.find(
    (p) => p.id === registrationDatum.programmeType
  );

  const paymentStatus = getPaymentStatus({
    registrationDatum,
    parish,
    catecheticalYear: programme.catecheticalYear,
  });

  useEffect(() => {
    if (!!selectedParishesConfig && !selectedParishesConfig.isPayplusEnabled) {
      setPaymentStage(4);
      return;
    }

    const payplusAssignmentCollection = db.collection("payplusassignments");

    payplusAssignmentCollection
      .where("type", "==", "CATCH")
      .where("orgId", "==", selectedParishesConfig.id)
      .where("additionalInfo.programmeId", "==", programme.id)
      .where("additionalInfo.level", "==", registrationDatum.level)
      .get()
      .then(
        (result) => {
          if (result.docs.length === 1) {
            const data = result.docs[0].data();

            getPayplusCampaign(data.payplusCampaign.id);
          } else if (result.docs.length === 0) {
            setPaymentStage(5);
            console.log("Payplus assignment not found");
          } else {
            setPaymentStage(5);
            console.log("Multiple Pay+ assignments");
          }
        },
        (error) => {
          setPaymentStage(5);
          console.log("Error getting Pay+ assignment");
        }
      );

    const getPayplusCampaign = (campaignId) => {
      const payplusCampaignCollection = db
        .collection("paypluscampaigns")
        .doc(campaignId);

      payplusCampaignCollection.get().then(
        (doc) => {
          const data = doc.data();
          if (data === undefined) {
            setPaymentStage(5);
            console.log("Pay+ campaign not found");
          } else {
            setPayplusCampaign({
              id: campaignId,
              amount: data.amount,
            });
            setPaymentStage(1);
          }
        },
        (error) => {
          setPaymentStage(5);
          console.log("Error getting Pay+ campaign");
        }
      );
    };
  }, [db, programme.id, registrationDatum.level, selectedParishesConfig]);

  const handlePayPlusSuccess = () => {
    makeSuccessfulPayment({
      registrationDatum,
      parish,
      catecheticalYear: programme.catecheticalYear,
      registrationCollection,
      paymentAmount: payplusCampaign.amount,
    });
    setPaymentStage(3);
  };

  const handlePayPlusFailure = () => {};

  const handlePayPlusFailureButton = () => {
    setPaymentStage(1);
  };

  const handlePayPlusReturnButton = () => {
    setPaymentStage(1);
  };

  switch (paymentStage) {
    case 0:
      return (
        <>
          <SpinnerText text="Loading..." />
        </>
      );
    case 1:
      switch (paymentStatus) {
        case "pending":
          return (
            <>
              <h4>Payment Pending</h4>
              <div className="text-center">
                <p>
                  You are making payment for Catechetical Year{" "}
                  {programme.catecheticalYear}.
                </p>
                <Card>
                  <Card.Header className="text-muted font-weight-bold">
                    RECIPIENT DETAILS
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{selectedParishesConfig.parish}</Card.Title>
                    <Card.Subtitle>{programme.name}</Card.Subtitle>
                    <Card.Text className="mt-2">
                      {levelIdToName(registrationDatum.level)}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="font-weight-bold biggerfontsize text-secondary">
                    ${payplusCampaign.amount}
                  </Card.Footer>
                </Card>
              </div>
              <Button
                className="my-0 w-50"
                variant="primary"
                size="lg"
                onClick={() => setPaymentStage(2)}
                disabled={payplusCampaign.amount === -1}
              >
                Make Payment
              </Button>
              <hr className="w-100" />
              <Container>
                <Row className="mb-2 justify-content-center">
                  <p>
                    If you are unable to pay, please provide the reason below to
                    request for a waiver:
                  </p>
                </Row>
                <Row className="mb-4">
                  <Col xs={3} />
                  <Col xs={6}>
                    <Form.Control
                      size="lg"
                      placeholder="e.g. Low income household, etc."
                      onChange={(e) => setWaiverReason(e.target.value)}
                    />
                  </Col>
                  <Col xs={3} />
                </Row>
                <Row>
                  <Col sm={6} xs={12}>
                    <Button
                      className="my-3 w-100"
                      variant="info"
                      size="lg"
                      onClick={() => {
                        requestWaiver({
                          registrationDatum,
                          parish,
                          catecheticalYear: programme.catecheticalYear,
                          waiverReason,
                          registrationCollection,
                        });
                      }}
                      disabled={!waiverReason.length}
                    >
                      Request for Waiver
                    </Button>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </>
          );
        case "waiverRequested":
          return (
            <>
              <h4>Waiver Requested</h4>
              <div className="text-center">
                <p>
                  Your waiver request for catechetical year{" "}
                  {programme.catecheticalYear} is being processed.
                </p>
                <p>If you are able to pay, you may make payment below:</p>
                <p>
                  The class fund amount for {programme.catecheticalYear} is{" "}
                  <b>${payplusCampaign.amount}</b>
                </p>
              </div>
              <Button
                className="my-0 w-50"
                variant="primary"
                size="lg"
                onClick={() => setPaymentStage(2)}
                disabled={payplusCampaign.amount === -1}
              >
                Make Payment
              </Button>
              <hr className="w-100" />
              <Container>
                <Row>
                  <Col>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </>
          );
        case "waiverRejected":
          return (
            <>
              <h4>Waiver Request Rejected</h4>
              <div className="text-center">
                <p>
                  Please make payment for catechetical year{" "}
                  {programme.catecheticalYear}:
                </p>
                <p>
                  The class fund amount for {programme.catecheticalYear} is{" "}
                  <b>${payplusCampaign.amount}</b>
                </p>
              </div>
              <Button
                className="my-0 w-50"
                variant="primary"
                size="lg"
                onClick={() => setPaymentStage(2)}
                disabled={payplusCampaign.amount === -1}
              >
                Make Payment
              </Button>
              <hr className="w-100" />
              <Container>
                <Row className="mb-2 justify-content-center">
                  <p>
                    If you would like to submit a new waiver request, please
                    provide the reason below:
                  </p>
                </Row>
                <Row className="mb-4">
                  <Col xs={3} />
                  <Col xs={6}>
                    <Form.Control
                      size="lg"
                      placeholder="e.g. Low income household, etc."
                      onChange={(e) => setWaiverReason(e.target.value)}
                    />
                  </Col>
                  <Col xs={3} />
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="my-3 w-100"
                      variant="info"
                      size="lg"
                      onClick={() => {
                        requestWaiver({
                          registrationDatum,
                          parish,
                          catecheticalYear: programme.catecheticalYear,
                          waiverReason,
                          registrationCollection,
                        });
                      }}
                      disabled={!waiverReason.length}
                    >
                      Request for Waiver
                    </Button>
                  </Col>
                  <Col>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </>
          );
        case "waiverApproved":
          return (
            <>
              <MoneyOffIcon style={{ fontSize: "130px", color: "green" }} />
              <h4>Payment Waived</h4>
              <p>
                Your payment has been waived for catechetical year{" "}
                {programme.catecheticalYear}!
              </p>
              <Container>
                <Row>
                  <Col>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </>
          );
        case "paid":
          return (
            <>
              <CheckCircleOutlineIcon
                style={{ fontSize: "140px", color: "green" }}
              />
              <h4>Payment Completed</h4>
              <p>
                You have already paid for catechetical year{" "}
                {programme.catecheticalYear}!
              </p>
              <Container>
                <Row>
                  <Col>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </>
          );
        case "parisherror":
          return (
            <>
              <h4>Error</h4>
              <p>
                Your Parish could not be found. Please contact support at{" "}
                <a href="mycatholicsg@catholic.org.sg">
                  mycatholicsg@catholic.org.sg
                </a>
                .
              </p>
              <Container>
                <Row>
                  <Col>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </>
          );
        default:
          return null;
      }
    case 2:
      if (!payplusCampaign.id) {
        return (
          <>
            <div
              className="mt-4 pt-4 spinner-border text-danger"
              role="status"
            />
            <div>Loading...</div>
          </>
        );
      } else {
        return (
          <>
            <GenQRPayment
              userObj={user}
              selectedCampaignId={payplusCampaign.id}
              onFailureCB={handlePayPlusFailure}
              onSuccessCB={() => {
                handlePayPlusSuccess();
              }}
              onReturnBtnCB={handlePayPlusReturnButton}
              onFailureBtnCB={handlePayPlusFailureButton}
              step={1}
            />
          </>
        );
      }
    case 3:
      return (
        <>
          <CheckCircleOutlineIcon
            style={{ fontSize: "140px", color: "green" }}
          />
          <h4>Payment Successful</h4>
          <div>
            <p>Thank you for your contribution!</p>
          </div>
        </>
      );
    case 4:
      return (
        <>
          <h4>
            Pay+ E-Payments is not enabled for your Catechetical Programme!
          </h4>
          <div>
            <p>
              Please refer to your child's Catechist for payment instructions,
              or contact the Programme Coordinator at{" "}
              <a href={`mailto:${programme.enquiryEmail}`}>
                {programme.enquiryEmail}
              </a>
              .
            </p>
          </div>
        </>
      );
    case 5:
      return (
        <>
          <h4>Pay+ E-Payments is not enabled for your Level!</h4>
          <div>
            <p>
              Please refer to your child's Catechist for payment instructions,
              or contact the Programme Coordinator at{" "}
              <a href={`mailto:${programme.enquiryEmail}`}>
                {programme.enquiryEmail}
              </a>
              .
            </p>
          </div>
        </>
      );
    default:
      return null;
  }
}

export default withLayout(ClassFund, "classFund");
