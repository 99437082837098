import React, { useState, useEffect } from "react";
import fire from "../../../services/fire";
import PaymentSelector from "./PaymentSelector";
import QRPaymentDisplay from "./QRPaymentDisplay";

const INITIAL_STATE = {
  userObj: {},
  selectedCampaign: {},
  hasConsent: false,
  step: 0,
};

const GenQRPayment = ({
  userObj = {},
  selectedCampaignId = "",
  onFailureBtnCB = () => {},
  onFailureCB = () => {},
  onSuccessBtnCB = () => {},
  onSuccessCB = () => {},
  onReturnBtnCB = () => {},
  showSuccessBtn = true,
  showFailureBtn = true,
  showReturnBtn = true,
  showPaymentSelectorReturnBtn = true,
  step = 0,
  amount = "0",
}) => {
  // console.log(amount);
  const [state, setState] = useState({ ...INITIAL_STATE, userObj, step });

  useEffect(() => {
    fire
      .firestore()
      .collection("paypluscampaigns")
      .where("id", "==", selectedCampaignId)
      .get()
      .then((campaignResultSnapshot) => {
        if (campaignResultSnapshot.docs.length > 0) {
          campaignResultSnapshot.docs.map((campaignDoc) => {
            const campaignObj = campaignDoc.data();
            const orgId = campaignObj.orgId;
            fire
              .firestore()
              .collection("payplusorgs")
              .where("orgId", "==", orgId)
              .get()
              .then((orgResultSnapshot) => {
                orgResultSnapshot.docs.map((orgDoc) => {
                  const orgObj = orgDoc.data();
                  setState({
                    ...state,
                    selectedCampaign: {
                      ...campaignObj,
                      orgName: orgObj.orgName,
                      orgShortName: orgObj.orgShortName,
                      payplusUEN: orgObj.payplusUEN,
                      amount: amount === "0" ? campaignObj.amount : amount,
                    },
                  });
                });
              });
          });
        } else {
          // No campaign with that ID found, show error
          onFailureCB();
        }
      });
  }, [selectedCampaignId]);

  const renderPayment = () => {
    switch (state.step) {
      case 0:
        return (
          <PaymentSelector
            isGeneral={true}
            genSelectedCampaign={state.selectedCampaign}
            genState={{
              hasConsent: state.hasConsent,
              displayPaymentSelectorReturnBtn: showPaymentSelectorReturnBtn,
            }}
            genCallbacks={{
              onConsent: (res) => {
                setState({ ...state, hasConsent: res });
              },
              onReturnBtnCB,
              onNextStep: () => {
                setState({ ...state, step: 1 });
              },
            }}
          />
        );
      case 1:
        return (
          <QRPaymentDisplay
            isGeneral={true}
            genSelectedCampaign={state.selectedCampaign}
            genState={{ userObj: state.userObj }}
            userObj
            genSettings={{
              showReturnBtn,
              showSuccessBtn,
              showFailureBtn,
            }}
            genCallbacks={{
              onSuccessCB,
              onSuccessBtnCB,
              onReturnBtnCB,
              onFailureCB,
              onFailureBtnCB,
            }}
          />
        );
      default:
        return (
          <PaymentSelector
            isGeneral={true}
            genSelectedCampaign={state.selectedCampaign}
            genState={{
              hasConsent: state.hasConsent,
              showPaymentSelectorReturnBtn: showPaymentSelectorReturnBtn,
            }}
            genCallbacks={{
              onConsent: (res) => {
                setState({ ...state, hasConsent: res });
              },
              onReturnBtnCB,
              onNextStep: () => {
                setState({ ...state, step: 1 });
              },
            }}
          />
        );
    }
  };

  return renderPayment();
};

export default GenQRPayment;
