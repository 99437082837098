import React, { useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";

import Moment from "react-moment";

// import { getShortMonths } from "../../../../../utils/utils";

import { getOrgCompleteNameById } from "../../../../../services/organisation";
import { levelIdToName } from "../../services/levels";
import { useLookupConfig } from "../hooks/hooks";

import { tsToMoment } from "../services/formatTimestamp";

import { downloadCert } from "../services/downloadCert";

function getSacramentChurchNameFromId(sacrament) {
  if (!sacrament) return <>&mdash;</>;
  const completeName = getOrgCompleteNameById(sacrament.church);
  if (!completeName) return <>&mdash;</>;
  return `${completeName} (${
    sacrament.receivedInSingapore ? "Singapore" : "Overseas"
  })`;
}

function handleAddNote(
  noteText,
  selectedItem,
  user,
  registrationCollection,
  setNoteText,
  setIsAddingNote,
  setSelectedItem,
  setSelectedNotes
) {
  if (!selectedItem.id || !user.uid) {
    setIsAddingNote(false);

    alert("Something went wrong while adding a note.");

    return;
  }

  const registrationDoc = registrationCollection.doc(selectedItem.id);

  const noteData = {
    createdAt: new Date(),
    note: noteText,
    noteBy: user.uid,
    noteByName: user.displayName,
  };

  let finalNotes = selectedItem.notes ? [...selectedItem.notes] : [];

  finalNotes.push(noteData);

  const docData = {
    ...selectedItem,
    notes: finalNotes,
  };

  Object.keys(docData).forEach(
    (key) => docData[key] === undefined && delete docData[key]
  );

  registrationDoc
    .update(docData)
    .then(() => {
      alert("Note successfully added.");

      setNoteText("");
      setSelectedItem(docData);
      setSelectedNotes([...docData.notes]);
      setIsAddingNote(false);
    })
    .catch((error) => {
      console.error("handleAddNote::error:", error);

      alert("Something went wrong while adding a note.");
    });
}

function parseRegistrationType(registrationType) {
  switch (registrationType) {
    case "new":
      return "New Registration";
    case "onboarding":
      return "Onboard Existing";
    case "transfer":
      return "External Transfer-In";
    default:
      return null;
  }
}

/**
 * @typedef RegistrationDetailsProps
 * @prop {import("../../hooks/hooks").RegistrationDatum} selectedItem
 * @prop {React.Dispatch<React.SetStateAction<import("../../hooks/hooks").RegistrationDatum>>} setSelectedItem
 */

/**
 * @param {RegistrationDetailsProps} props
 */
export default function RegistrationDetails({
  user,
  registrationCollection,
  selectedItem,
  setSelectedItem,
  selectedNotes,
  setSelectedNotes,
}) {
  const lookupConfig = useLookupConfig();

  const [isEditingNote, setIsEditingNote] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);

  const [isDownloadingCustodyDoc, setIsDownloadingCustodyDoc] = useState(false);
  const [isDownloadingBaptismCert, setIsDownloadingBaptismCert] =
    useState(false);
  const [isDownloadingConfirmationCert, setIsDownloadingConfirmationCert] =
    useState(false);

  const isDownloading = {
    custodyDoc: isDownloadingCustodyDoc,
    baptismCert: isDownloadingBaptismCert,
    confirmationCert: isDownloadingConfirmationCert,
  };
  const setIsDownloading = {
    custodyDoc: setIsDownloadingCustodyDoc,
    baptismCert: setIsDownloadingBaptismCert,
    confirmationCert: setIsDownloadingConfirmationCert,
  };

  const [noteText, setNoteText] = useState("");

  function noteTextChange({ currentTarget: input }) {
    setNoteText(input.value);
  }

  useEffect(() => {
    if (isAddingNote) {
      handleAddNote(
        noteText,
        selectedItem,
        user,
        registrationCollection,
        setNoteText,
        setIsAddingNote,
        setSelectedItem,
        setSelectedNotes
      );
    }

    return () => {};
  }, [
    isAddingNote,
    noteText,
    registrationCollection,
    selectedItem,
    setSelectedItem,
    setSelectedNotes,
    user,
  ]);

  function displayPostingTime(date) {
    if (!date) {
      return "";
    }
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate();
    const year = date.getFullYear().toString().substr(-2);
    const hour = ((date.getHours() + 11) % 12) + 1;
    const minute = String(date.getMinutes()).padStart(2, "0");
    const meridiem = date.getHours() >= 12 ? "PM" : "AM";

    const formattedDateStr = `${day}/${month}/${year} ${hour}:${minute} ${meridiem}`;

    return formattedDateStr;
  }

  function handleEditNote(
    user,
    index,
    selectedItem,
    selectedNotes,
    registrationCollection,
    setIsEditingNote,
    setSelectedNotes,
    setSelectedItem
  ) {
    if (!selectedItem.id || !user.uid) {
      setIsEditingNote(false);

      alert("Something went wrong while editing note.");

      return;
    }
    const notes = [...selectedNotes];

    notes[index].edit = false;
    notes[index].noteEditedBy = user.uid;
    notes[index].noteEditedByName = user.displayName;
    notes[index].editedAt = new Date();

    const newNotes = notes.map(function (e) {
      const { edit, ...rest } = e;
      return rest;
    });

    const registrationDoc = registrationCollection.doc(selectedItem.id);

    const docData = {
      ...selectedItem,
      notes: newNotes,
    };

    Object.keys(docData).forEach(
      (key) => docData[key] === undefined && delete docData[key]
    );

    registrationDoc
      .update(docData)
      .then(() => {
        alert("Note successfully edited.");

        setSelectedItem(docData);
        setSelectedNotes(notes);
        setIsEditingNote(false);
      })
      .catch((error) => {
        console.error("handleEditNote::error:", error);

        alert("Something went wrong while editing note.");
      });
  }

  function handlePreEditNote(index) {
    setIsEditingNote(true);

    setTimeout(() => {
      handleEditNote(
        user,
        index,
        selectedItem,
        selectedNotes,
        registrationCollection,
        setIsEditingNote,
        setSelectedNotes,
        setSelectedItem
      );
    }, 500);
  }

  if (selectedItem == null) return null;

  const { parish, programme, timeslot } = lookupConfig(
    selectedItem.selectedParishId,
    selectedItem.programmeType,
    selectedItem.timeslot
  );

  // console.log(selectedItem);
  // console.log({ parish, programme, timeslot });

  return (
    <>
      <Row className="border-bottom py-2">
        <Col xs={12} sm={6}>
          <div className="mb-sm-3">
            <div className="mb-sm-3">
              <h5>Child's Basic Details</h5>
            </div>
            <span className="font-weight-bold">Full Name</span>
            <div className="mb-sm-3">
              {selectedItem ? selectedItem.name : <>&mdash;</>}
            </div>
            <span className="font-weight-bold">Sex</span>
            <div className="mb-sm-3">
              {selectedItem ? selectedItem.sex : <>&mdash;</>}
            </div>
            <span className="font-weight-bold">Date of Birth</span>
            <div className="mb-sm-3">
              {selectedItem ? (
                <Moment
                  date={tsToMoment(selectedItem.dateOfBirth)}
                  format="DD MMM YYYY"
                />
              ) : (
                <>&mdash;</>
              )}
            </div>
            <span className="font-weight-bold">Country of Birth</span>
            <div className="mb-sm-3">
              {selectedItem ? selectedItem.placeOfBirth : <>&mdash;</>}
            </div>
            <span className="font-weight-bold">Nationality</span>
            <div className="mb-sm-3">
              {selectedItem ? selectedItem.nationality : <>&mdash;</>}
            </div>
            <span className="font-weight-bold">Mother's Religion</span>
            <div className="mb-sm-3">
              {selectedItem && selectedItem.motherReligion ? (
                selectedItem.motherReligion
              ) : (
                <>&mdash;</>
              )}
            </div>
            <span className="font-weight-bold">Father's Religion</span>
            <div className="mb-sm-3">
              {selectedItem && selectedItem.fatherReligion ? (
                selectedItem.fatherReligion
              ) : (
                <>&mdash;</>
              )}
            </div>
          </div>
          <div className="mb-sm-3">
            <div className="mb-sm-3">
              <h5>Address</h5>
            </div>
            <span className="font-weight-bold">Address</span>
            <div>
              {selectedItem &&
                (selectedItem.isAddressLanded
                  ? `${selectedItem.blkNo} ${selectedItem.roadName}`
                  : `${selectedItem.blkNo} ${selectedItem.roadName} #${selectedItem.floorNumber}-${selectedItem.unitNumber}`)}
            </div>
            {selectedItem && (
              <div className="mb-sm-3">SINGAPORE {selectedItem.postalCode}</div>
            )}
          </div>
          <div className="mb-sm-3">
            <div className="mb-sm-3">
              <h5>Child's Sacraments</h5>
            </div>
            <span className="font-weight-bold">Baptism</span>
            <div className="mb-sm-3">
              {selectedItem?.sacraments?.baptism?.received ? (
                selectedItem?.sacraments?.baptism?.cert?.path ? (
                  <Badge variant="success">RECEIVED</Badge>
                ) : (
                  <Badge variant="warning">RECEIVED, NO CERT</Badge>
                )
              ) : (
                <Badge variant="secondary">NOT RECEIVED</Badge>
              )}
              <ul>
                {selectedItem?.sacraments?.baptism?.received && (
                  <>
                    <li>
                      {selectedItem?.sacraments?.baptism?.date ? (
                        <Moment
                          date={tsToMoment(
                            selectedItem.sacraments.baptism.date
                          )}
                          format="DD MMM YYYY"
                        />
                      ) : (
                        <>&mdash;</>
                      )}
                    </li>
                    <li>
                      {getSacramentChurchNameFromId(
                        selectedItem.sacraments?.baptism
                      )}
                    </li>
                    <li>
                      Certificate
                      <br />
                      <Button
                        disabled={isDownloading.baptismCert}
                        variant="secondary"
                        className="text-wrap text-dark"
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderColor: "#f3f3f3",
                        }}
                        onClick={async () => {
                          setIsDownloading.baptismCert(true);
                          await downloadCert(
                            selectedItem.sacraments?.baptism?.cert,
                            user
                          );
                          setIsDownloading.baptismCert(false);
                        }}
                      >
                        {isDownloading.baptismCert && (
                          <div
                            className="spinner-border mr-1"
                            role="status"
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        <AttachFileOutlinedIcon
                          className="mr-1"
                          style={{ color: "#7c7c7c" }}
                        />
                        {selectedItem.sacraments?.baptism?.cert.path ? (
                          selectedItem.sacraments?.baptism?.cert.name
                        ) : (
                          <>&mdash;</>
                        )}
                      </Button>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <span className="font-weight-bold">First Reconciliation</span>
            <div className="mb-sm-3">
              {selectedItem?.sacraments?.reconciliation?.received ? (
                <Badge variant="success">RECEIVED</Badge>
              ) : (
                <Badge variant="secondary">NOT RECEIVED</Badge>
              )}
              <ul>
                {selectedItem?.sacraments?.reconciliation?.received && (
                  <>
                    <li>
                      {selectedItem?.sacraments?.reconciliation?.date ? (
                        <Moment
                          date={tsToMoment(
                            selectedItem.sacraments.reconciliation.date
                          )}
                          format="DD MMM YYYY"
                        />
                      ) : (
                        <>&mdash;</>
                      )}
                    </li>
                    <li>
                      {selectedItem.sacraments?.reconciliation
                        ?.receivedInSingapore
                        ? getOrgCompleteNameById(
                            selectedItem.sacraments?.reconciliation?.church
                          ) + " (Singapore)"
                        : selectedItem.sacraments?.reconciliation?.church +
                            " (Overseas)" ?? <>&mdash;</>}
                    </li>
                  </>
                )}
              </ul>
            </div>
            <span className="font-weight-bold">First Holy Communion</span>
            <div className="mb-sm-3">
              {selectedItem?.sacraments?.eucharist?.received ? (
                <Badge variant="success">RECEIVED</Badge>
              ) : (
                <Badge variant="secondary">NOT RECEIVED</Badge>
              )}
              <ul>
                {selectedItem?.sacraments?.eucharist?.received && (
                  <>
                    <li>
                      {typeof selectedItem?.sacraments?.eucharist?.date ? (
                        <Moment
                          date={tsToMoment(
                            selectedItem.sacraments.eucharist.date
                          )}
                          format="DD MMM YYYY"
                        />
                      ) : (
                        <>&mdash;</>
                      )}
                    </li>
                    <li>
                      {selectedItem.sacraments?.eucharist?.receivedInSingapore
                        ? getOrgCompleteNameById(
                            selectedItem.sacraments?.eucharist?.church
                          ) + " (Singapore)"
                        : selectedItem.sacraments?.eucharist?.church +
                            " (Overseas)" ?? <>&mdash;</>}
                    </li>
                  </>
                )}
              </ul>
            </div>
            <span className="font-weight-bold">Confirmation</span>
            <div className="mb-sm-3">
              {selectedItem?.sacraments?.confirmation?.received ? (
                selectedItem?.sacraments?.confirmation?.cert?.path ? (
                  <Badge variant="success">RECEIVED</Badge>
                ) : (
                  <Badge variant="warning">RECEIVED, NO CERT</Badge>
                )
              ) : (
                <Badge variant="secondary">NOT RECEIVED</Badge>
              )}
              <ul>
                {selectedItem?.sacraments?.confirmation?.received && (
                  <>
                    <li>
                      {selectedItem?.sacraments?.confirmation?.date ? (
                        <Moment
                          date={tsToMoment(
                            selectedItem.sacraments.confirmation.date
                          )}
                          format="DD MMM YYYY"
                        />
                      ) : (
                        <>&mdash;</>
                      )}
                    </li>
                    <li>
                      {selectedItem.sacraments?.confirmation
                        ?.receivedInSingapore
                        ? getOrgCompleteNameById(
                            selectedItem.sacraments?.confirmation?.church
                          ) + " (Singapore)"
                        : selectedItem.sacraments?.confirmation?.church +
                            " (Overseas)" ?? <>&mdash;</>}
                    </li>
                    <li>
                      Certificate
                      <br />
                      <Button
                        disabled={isDownloading.confirmationCert}
                        variant="secondary"
                        className="text-wrap text-dark"
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderColor: "#f3f3f3",
                        }}
                        onClick={async () => {
                          setIsDownloading.confirmationCert(true);
                          await downloadCert(
                            selectedItem.sacraments?.confirmation?.cert,
                            user
                          );
                          setIsDownloading.confirmationCert(false);
                        }}
                      >
                        {isDownloading.confirmationCert && (
                          <div
                            className="spinner-border mr-1"
                            role="status"
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        <AttachFileOutlinedIcon
                          className="mr-1"
                          style={{ color: "#7c7c7c" }}
                        />
                        {selectedItem.sacraments?.confirmation?.cert.path ? (
                          selectedItem.sacraments?.confirmation?.cert.name
                        ) : (
                          <>&mdash;</>
                        )}
                      </Button>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="mb-sm-3">
            <div className="mb-sm-3">
              <h5>Child's Education</h5>
            </div>
            <span className="font-weight-bold">
              School
              {programme?.registrationYear &&
                ` (as of ${programme.registrationYear})`}
            </span>
            <div>{selectedItem?.school || <>&mdash;</>}</div>
            <div className="mb-sm-3">
              {selectedItem?.schoolLevel || <>&mdash;</>}
            </div>
          </div>
          <div className="mb-sm-3">
            <div className="mb-sm-3">
              <h5>Child's Miscellaneous</h5>
            </div>
            <span className="font-weight-bold">Additional Information</span>
            <div className="mb-sm-3">
              {selectedItem?.additionalInformation || <>&mdash;</>}
            </div>
            <span className="font-weight-bold">
              Siblings attending Catechesis in same Parish
            </span>
            {selectedItem?.siblingsDetails ? (
              <ul className="mb-sm-3">
                {selectedItem.siblingsDetails.map((data, index) => (
                  <li className="w-100" key={`sibling-${index}`}>
                    {data.siblingFullName || <>&mdash;</>} (
                    {data.siblingLevel && data.siblingClass ? (
                      `${levelIdToName(parseInt(data.siblingLevel))} ${
                        data.siblingClass
                      }`
                    ) : (
                      <>&mdash;</>
                    )}
                    )
                  </li>
                ))}
              </ul>
            ) : (
              <>&mdash;</>
            )}
          </div>
        </Col>
        <Col xs={12} sm={6} className="border-left">
          <div className="mb-sm-3">
            <div className="mb-sm-3">
              <h5>Selected Programme</h5>
            </div>
            <span className="font-weight-bold">Parish</span>
            <div className="mb-sm-3">{parish?.parish ?? <>&mdash;</>}</div>
            <span className="font-weight-bold">Programme</span>
            <div className="mb-sm-3">{programme?.name ?? <>&mdash;</>}</div>
            <span className="font-weight-bold">Level</span>
            <div className="mb-sm-3">
              {selectedItem ? (
                <>
                  {levelIdToName(
                    +selectedItem.level +
                      (programme?.catecheticalYear ?? 0) -
                      (selectedItem.registrationYear ?? 0) -
                      (selectedItem.retainedYears?.filter(
                        (year) => year < programme?.catecheticalYear ?? 0
                      )?.length ?? 0)
                  )}
                  {selectedItem.retainedYears &&
                    ` (retained ${selectedItem.retainedYears.join(", ")})`}
                </>
              ) : (
                <>&mdash;</>
              )}
            </div>
            <span className="font-weight-bold">Timeslot</span>
            <div className="mb-sm-3">{timeslot?.name ?? <>&mdash;</>}</div>
            <span className="font-weight-bold">Registration Type</span>
            <div className="mb-sm-3">
              {parseRegistrationType(selectedItem?.registrationType) ?? (
                <>&mdash;</>
              )}
            </div>
          </div>
          <div className="mb-sm-3">
            <div className="mb-sm-3">
              <h5>Guardian Details</h5>
            </div>
            <span className="font-weight-bold">Name</span>
            <div>
              {selectedItem?.guardianCode?.guardianName ?? <>&mdash;</>}
            </div>
            <br />
            <span className="font-weight-bold">Relationship</span>
            <div>
              {selectedItem?.guardianCode ? (
                selectedItem.guardianCode.guardianRelationship
              ) : (
                <>&mdash;</>
              )}
            </div>
            <br />
            <span className="font-weight-bold">Proof of Custody</span>
            {selectedItem?.guardianCode ? (
              <div>
                <Button
                  disabled={isDownloading.custodyDoc}
                  variant="secondary"
                  className="text-wrap text-dark"
                  style={{
                    backgroundColor: "#f3f3f3",
                    borderColor: "#f3f3f3",
                  }}
                  onClick={async () => {
                    setIsDownloading.custodyDoc(true);
                    await downloadCert(
                      { path: selectedItem.guardianCode?.custodyDoc },
                      user
                    );
                    setIsDownloading.custodyDoc(false);
                  }}
                >
                  {isDownloading.custodyDoc && (
                    <div
                      className="spinner-border mr-1"
                      role="status"
                      style={{ width: "1.5rem", height: "1.5rem" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <AttachFileOutlinedIcon
                    className="mr-1"
                    style={{ color: "#7c7c7c" }}
                  />
                  {selectedItem?.guardianCode?.custodyDoc ? (
                    selectedItem.guardianCode?.custodyDocFileName
                  ) : (
                    <>&mdash;</>
                  )}
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="mb-sm-3">
            <div className="mb-sm-3">
              <h5>Contact Details</h5>
            </div>
            <span className="font-weight-bold">Preferred Contact</span>
            <div>{selectedItem?.mainContact?.name ?? <>&mdash;</>}</div>
            <ul>
              <li>
                Relationship:{" "}
                {selectedItem?.mainContact ? (
                  selectedItem.mainContact.relationship === "Others" ? (
                    selectedItem.mainContact.relationshipOthers
                  ) : (
                    selectedItem.mainContact.relationship
                  )
                ) : (
                  <>&mdash;</>
                )}
              </li>
              <li>
                Religion:{" "}
                {selectedItem?.mainContact ? (
                  selectedItem.mainContact.religion
                ) : (
                  <>&mdash;</>
                )}
              </li>
              <li>
                Email:{" "}
                {selectedItem?.mainContact ? (
                  selectedItem.mainContact.email
                ) : (
                  <>&mdash;</>
                )}
              </li>
              <li>
                Mobile:{" "}
                {selectedItem?.mainContact?.mobileNo !== "+65" ? (
                  selectedItem.mainContact.mobileNo
                ) : (
                  <>&mdash;</>
                )}
              </li>
              <li>
                Volunteer as Catechist:{" "}
                {selectedItem?.mainContact?.volunteer ? "Y" : "N"}
              </li>
            </ul>
            <span className="font-weight-bold">Secondary Contact</span>
            <div>
              {selectedItem?.secondaryContact?.name ?? (
                <div className="text-muted">None</div>
              )}
            </div>
            {selectedItem?.secondaryContact?.exists && (
              <ul>
                <li>
                  Relationship:{" "}
                  {selectedItem?.secondaryContact ? (
                    selectedItem.secondaryContact.relationship === "Others" ? (
                      selectedItem.secondaryContact.relationshipOthers
                    ) : (
                      selectedItem.secondaryContact.relationship
                    )
                  ) : (
                    <>&mdash;</>
                  )}
                </li>
                <li>
                  Religion:{" "}
                  {selectedItem?.secondaryContact ? (
                    selectedItem.secondaryContact.religion
                  ) : (
                    <>&mdash;</>
                  )}
                </li>
                <li>
                  Email:{" "}
                  {selectedItem?.secondaryContact ? (
                    selectedItem.secondaryContact.email
                  ) : (
                    <>&mdash;</>
                  )}
                </li>
                <li>
                  Mobile:{" "}
                  {selectedItem?.secondaryContact?.mobileNo !== "+65" ? (
                    selectedItem.secondaryContact.mobileNo
                  ) : (
                    <>&mdash;</>
                  )}
                </li>
                <li>
                  Volunteer as Catechist:{" "}
                  {selectedItem?.secondaryContact?.volunteer ? "Y" : "N"}
                </li>
                <li>
                  Emergency Only:{" "}
                  {selectedItem?.secondaryContact?.emergencies ? "Y" : "N"}
                </li>
              </ul>
            )}
          </div>
        </Col>
      </Row>
      <Row className="border-bottom py-2">
        <Col>
          <div className="mb-sm-3">
            <h5>Admin Notes</h5>
          </div>
          <div className="noprint">
            <div className="form-group">
              <textarea
                onChange={noteTextChange}
                value={noteText}
                disabled={isAddingNote || isEditingNote}
                type="text"
                className="form-control form-control-lg"
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setIsAddingNote(true)}
                disabled={isAddingNote || isEditingNote || noteText === ""}
              >
                Add Note
              </Button>
            </div>
          </div>
          {selectedNotes.length ? (
            <Table className="mt-3">
              <tbody>
                {selectedNotes.toReversed().map((noteDetails, index) => {
                  let postingTime = noteDetails.createdAt;

                  if (Object.hasOwn(postingTime, "_seconds")) {
                    postingTime = new Date(
                      postingTime._seconds * 1000 +
                        postingTime._nanoseconds / 1000000
                    );
                  } else if (postingTime.seconds) {
                    postingTime = new Date(
                      postingTime.seconds * 1000 +
                        postingTime.nanoseconds / 1000000
                    );
                  } else if (typeof postingTime === "string") {
                    postingTime = new Date(Date.parse(postingTime));
                  }

                  return (
                    <tr key={index}>
                      <td>
                        <div>
                          <span className="font-weight-bold">
                            {noteDetails.noteByName} &bull;{" "}
                            {displayPostingTime(postingTime)}
                          </span>
                          <span
                            className="text-primary noprint"
                            style={{
                              position: "absolute",
                              right: "70px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const newNotes = [...selectedNotes];

                              if (newNotes[index].edit) {
                                newNotes[index] = JSON.parse(
                                  JSON.stringify(selectedItem.notes[index])
                                );
                              } else {
                                newNotes[index].edit = !newNotes[index].edit;
                              }

                              setSelectedNotes([...newNotes]);
                            }}
                          >
                            {noteDetails.edit ? "Cancel Edit" : "Edit"}
                          </span>
                        </div>
                        {noteDetails.edit ? (
                          <>
                            <div className="form-group noprint">
                              <textarea
                                onChange={(e) => {
                                  const newNotes = [...selectedNotes];

                                  newNotes[index].note = e.target.value;

                                  setSelectedNotes([...newNotes]);
                                }}
                                value={noteDetails.note}
                                disabled={isAddingNote || isEditingNote}
                                type="text"
                                className="form-control form-control-lg"
                              />
                            </div>
                            <div className="d-flex justify-content-end noprint">
                              <Button
                                onClick={() => {
                                  if (!isAddingNote && !isEditingNote) {
                                    handlePreEditNote(index);
                                  }
                                }}
                                disabled={
                                  isAddingNote ||
                                  isEditingNote ||
                                  noteDetails.note === ""
                                }
                              >
                                Edit Note
                              </Button>
                            </div>
                          </>
                        ) : (
                          noteDetails.note
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : null}
        </Col>
      </Row>
    </>
  );
}
