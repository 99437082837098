import React from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";

import {
  guardianRegistrationStatusDictionary,
  registrationStatusToStatusName,
} from "../../services/registrationStatus";
import {
  paymentStatusDictionary,
  getPaymentStatus,
  getWaiverReason,
} from "../../services/payments";
import { getSacramentsSortKey } from "./tableUtils";

import { Column, UseSortByState } from "react-table";
import ParishPill from "../components/ParishPill";
import ProgrammePill from "../components/ProgrammePill";
import LevelPill from "../components/LevelPill";
import StatusPill from "../components/StatusPill";
import SacramentsPill from "../components/SacramentsPill";

// import { levelIdToName } from "../../services/levels";
import {
  StaticActionProps,
  StatusActionDictionary,
} from "../components/AbstractList";

import formatTimestamp from "./formatTimestamp";
import { RegistrationStatus, RegistrationDatum } from "../../hooks/hooks";
import { LookupConfig } from "../hooks/hooks";

import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import MailIcon from "@material-ui/icons/Mail";
import RepeatOneIcon from "@material-ui/icons/RepeatOne";
import SwapHorizSharpIcon from "@material-ui/icons/SwapHorizSharp";
import VisibilityIcon from "@material-ui/icons/Visibility";

/**
 * @typedef ViewButtonProps
 * @prop {RegistrationDatum} selectedItem
 * @prop {() => void} openModal
 */

/** @type {(lookupConfig: LookupConfig, viewButtonProps: ViewButtonProps) => Column<RegistrationDatum & {catecheticalYear: number}>[]} */
const getParishAdminColumnProps = (
  lookupConfig,
  { selectedItem, openModal }
) => [
  {
    id: "submittedAt",
    Header: "Submitted at",
    accessor: ({ submittedAt }) => submittedAt,
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">{formatTimestamp(value)}</div>
    ),
    width: 150,
    sortType: ({ original: rowA }, { original: rowB }) => {
      const timestampA = rowA.submittedAt;
      const timestampB = rowB.submittedAt;

      // Convert to JavaScript Date objects
      let dateA;

      if (Object.hasOwn(timestampA, "_seconds")) {
        dateA = new Date(
          timestampA._seconds * 1000 + timestampA._nanoseconds / 1000000
        );
      } else {
        dateA = new Date(
          timestampA.seconds * 1000 + timestampA.nanoseconds / 1000000
        );
      }

      let dateB;

      if (Object.hasOwn(timestampB, "_seconds")) {
        dateB = new Date(
          timestampB._seconds * 1000 + timestampB._nanoseconds / 1000000
        );
      } else {
        dateB = new Date(
          timestampB.seconds * 1000 + timestampB.nanoseconds / 1000000
        );
      }

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    },
  },
  {
    id: "status",
    Header: "Status",
    accessor: ({ status }) => registrationStatusToStatusName(status),
    Cell: ({ value }) => (
      <h6 className="m-0">
        <StatusPill
          statusDictionary={guardianRegistrationStatusDictionary}
          value={value}
        />
      </h6>
    ),
    width: 120,
    disableGlobalFilter: true,
  },
  {
    id: "name",
    Header: "Name",
    accessor: ({ name }) => name,
    Cell: ({ value }) => <div className="text-wrap pt-1 pb-1">{value}</div>,
    width: 150,
  },
  {
    id: "programme",
    Header: "Programme",
    accessor: ({ selectedParishId, programmeType }) => {
      if (typeof programmeType === "object")
        return {
          selectedParishId,
          name: programmeType.name,
          type: programmeType.id,
        };
      const programmeName = lookupConfig(selectedParishId, programmeType)
        ?.programme?.name;
      return { selectedParishId, name: programmeName, type: programmeType };
    },
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">
        {!!value.name ? (
          <h6 className="m-0">
            <ProgrammePill {...value} />
          </h6>
        ) : (
          value.type
        )}
      </div>
    ),
    sortType: ({ original: rowA }, { original: rowB }) =>
      Math.sign(+rowA.selectedParishId - +rowB.selectedParishId) ||
      (rowA.programmeType?.localeCompare?.(rowB.programmeType) ?? 0),
    width: 100,
    disableGlobalFilter: true,
  },
  {
    id: "level",
    Header: "Level",
    accessor: ({
      selectedParishId,
      programmeType,
      level,
      registrationYear,
      retainedYears,
    }) => {
      const catecheticalYear = lookupConfig(selectedParishId, programmeType)
        ?.programme?.catecheticalYear;
      if (!catecheticalYear || !registrationYear)
        return { level: +level, catecheticalYear: null };
      return {
        level:
          +level +
          catecheticalYear -
          registrationYear -
          (retainedYears?.filter((year) => year < catecheticalYear)?.length ??
            0),
        catecheticalYear,
      };
    },
    sortType: (rowA, rowB, id) => rowA.values[id].level - rowB.values[id].level,
    Cell: ({ value: { level, catecheticalYear } }) => (
      <span>
        <h6 className="m-0" style={{ display: "inline" }}>
          <LevelPill id={level} />
        </h6>{" "}
        {catecheticalYear && `(${catecheticalYear})`}
      </span>
    ),
    width: 100,
  },
  {
    id: "timeslot",
    Header: "Timeslot",
    accessor: ({ selectedParishId, programmeType, timeslot }) => ({
      selectedParishId,
      programmeType,
      timeslot,
    }),
    Cell: ({ value }) => (
      <div className="text-wrap pt-1 pb-1">
        {lookupConfig(
          value.selectedParishId,
          value.programmeType,
          value.timeslot
        )?.timeslot?.name ?? <>&mdash;</>}
      </div>
    ),
    sortType: ({ original: rowA }, { original: rowB }) =>
      Math.sign(+rowA.selectedParishId - +rowB.selectedParishId) ||
      (rowA.programmeType?.localeCompare?.(rowB.programmeType) ?? 0) ||
      Math.sign(+rowA.timeslot - +rowB.timeslot),
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "assignedClass",
    Header: "Class",
    accessor: ({ assignedClass }) => assignedClass,
    Cell: ({ value }) => <div className="text-wrap pt-1 pb-1">{value}</div>,
    width: 150,
    disableGlobalFilter: true,
  },
  {
    id: "sacraments",
    Header: "Sacraments",
    accessor: ({ sacraments }) => sacraments,
    Cell: ({ value }) => <SacramentsPill sacraments={value} />,
    sortType: (rowA, rowB, id) =>
      Math.sign(
        getSacramentsSortKey(rowA.values[id]) -
          getSacramentsSortKey(rowB.values[id])
      ),
    width: 600,
    disableGlobalFilter: true,
  },
  {
    id: "classFund",
    Header: "Class Fund",
    accessor: (registrationDatum) => {
      const { selectedParishId, programmeType } = registrationDatum;
      const catecheticalYear = (
        typeof programmeType === "object"
          ? programmeType
          : lookupConfig(selectedParishId, programmeType).programme
      )?.catecheticalYear;
      const props = {
        registrationDatum,
        catecheticalYear,
      };
      return {
        paymentStatus: getPaymentStatus(props),
        waiverReason: getWaiverReason(props),
      };
    },
    Cell: ({ value }) => (
      <div>
        {value.paymentStatus !== "waiverRequested" ? (
          <h6 className="m-0">
            <StatusPill
              statusDictionary={paymentStatusDictionary}
              value={value.paymentStatus}
            />
          </h6>
        ) : (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="waiverTooltip">
                <span style={{ fontWeight: "bold" }}>Reason</span>:{" "}
                {value.waiverReason}
              </Tooltip>
            }
          >
            <h6 className="m-0">
              <StatusPill
                statusDictionary={paymentStatusDictionary}
                value={value.paymentStatus}
              />
            </h6>
          </OverlayTrigger>
        )}
      </div>
    ),
    width: 120,
  },
  {
    id: "view",
    Header: "View",
    accessor: (registrationDatum) => registrationDatum,
    Cell: ({ value }) => (
      <Button
        id={`viewbutton-${value.id}`}
        variant="outline-secondary"
        className="w-100"
        onClick={() => openModal(value)}
        style={{ minWidth: 50 }}
        disabled={!!selectedItem?.id}
      >
        {selectedItem?.id === value.id ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <VisibilityIcon />
        )}
      </Button>
    ),
    width: 100,
    disableGlobalFilter: true,
    sticky: "right",
  },
];

/** @type {(lookupConfig: LookupConfig, viewButtonProps: ViewButtonProps) => Column<RegistrationDatum & {catecheticalYear: number, districtName: string, parishName: string}>[]} */
const getOverallAdminColumnProps = (
  lookupConfig,
  { selectedItem, openModal }
) => {
  const parishAdminColumnProps = getParishAdminColumnProps(lookupConfig, {
    selectedItem,
    openModal,
  });

  const programmeColumnIdx = parishAdminColumnProps.findIndex(
    ({ id }) => id === "programme"
  );

  return [
    ...parishAdminColumnProps.slice(0, programmeColumnIdx),
    {
      id: "parish",
      Header: "Parish",
      accessor: ({ selectedParishId }) => ({
        selectedParishId,
      }),
      Cell: ({ value }) => {
        const { parish } = lookupConfig(value.selectedParishId);
        return parish ? (
          <div className="text-wrap pt-1 pb-1">
            <h6 className="m-0">
              <ParishPill parish={parish} />
            </h6>
          </div>
        ) : (
          <>&mdash;</>
        );
      },
      sortType: ({ original: rowA }, { original: rowB }) =>
        Math.sign(+rowA.selectedParishId - +rowB.selectedParishId),
      width: 100,
      disableGlobalFilter: true,
    },
    ...parishAdminColumnProps.slice(
      programmeColumnIdx,
      parishAdminColumnProps.length
    ),
  ];
};

/** @type {UseSortByState<RegistrationDatum>} */
const sortByProps = [
  {
    id: "submittedAt",
    desc: true,
  },
];

/**
 * @typedef {"unassign" | "accept" | "reject" | "waive" | "email" | "retain"} RegistrationAction
 */

/**
 * @type {Record<RegistrationAction, StaticActionProps<RegistrationAction>>}
 */
const actions = {
  unassign: {
    action: "unassign",
    Icon: RemoveCircleIcon,
    title: "Set as Pending",
    variant: "outline-secondary",
  },
  accept: {
    action: "accept",
    Icon: CheckCircleIcon,
    title: "Accept",
    variant: "outline-success",
  },
  reject: {
    action: "reject",
    Icon: CancelIcon,
    title: "Reject",
    variant: "outline-danger",
  },
  waive: {
    action: "waive",
    Icon: MoneyOffIcon,
    title: "Waive Payment",
    variant: "success",
  },
  rejectwaiver: {
    action: "rejectwaiver",
    Icon: CancelOutlinedIcon,
    title: "Reject Waiver",
    variant: "danger",
  },
  email: {
    action: "email",
    Icon: MailIcon,
    title: "Send Email",
    variant: "primary",
  },
  retain: {
    action: "retain",
    Icon: RepeatOneIcon,
    title: "Retain Once",
    variant: "warning",
  },
  moveTo: {
    action: "moveTo",
    Icon: SwapHorizSharpIcon,
    title: "Move Class",
    variant: "info",
  },
};
/**
 * @type {Record<TransfersAction, StaticActionProps<TransfersAction>>}
 */
const disabledActions = Object.fromEntries(
  Object.keys(actions).map((key) => [key, { ...actions[key], disabled: true }])
);

/**
 * @type {StatusActionDictionary<RegistrationStatus, RegistrationAction>}
 */
const statusActionDictionary = {
  1: [
    [disabledActions.unassign, actions.accept, actions.reject],
    [actions.moveTo, actions.email],
  ],
  0: [
    [actions.unassign, disabledActions.accept, disabledActions.reject],
    [
      actions.retain, // TODO: Uncomment for Dec release
      actions.waive,
      actions.rejectwaiver,
      actions.moveTo,
      actions.email,
    ],
  ],
  2: [
    [actions.unassign, disabledActions.accept, disabledActions.reject],
    [actions.email],
  ],
};

const getParishAdminListProps = (lookupConfig, viewButtonProps) => ({
  columnProps: getParishAdminColumnProps(lookupConfig, viewButtonProps),
  sortByProps,
  statusActionDictionary,
});
const getOverallAdminListProps = (lookupConfig, viewButtonProps) => ({
  columnProps: getOverallAdminColumnProps(lookupConfig, viewButtonProps),
  sortByProps,
  statusActionDictionary,
});

export { getParishAdminListProps, getOverallAdminListProps };
