import {
  StatusDictionaryLite,
  RegistrationDatum,
  ParishConfig,
} from "../hooks/hooks";

import { firestore } from "firebase";

/** @typedef { "parisherror" | "pending" | "requestwaive" | "waived" | "paid" } PaymentStatus */

/**
 * @type {StatusDictionaryLite<PaymentStatus>}
 */
export const paymentStatusDictionary = {
  parisherror: {
    userLabel: "Error",
    adminLabel: "Error",
    bg: "dark",
  },
  pending: {
    userLabel: "Pending",
    adminLabel: "Pending",
    bg: "secondary",
  },
  waiverRequested: {
    userLabel: "Waive Requested",
    adminLabel: "Need Waiver",
    bg: "warning",
  },
  waiverApproved: {
    userLabel: "Waived",
    adminLabel: "Waived",
    bg: "info",
  },
  waiverRejected: {
    userLabel: "Waive Rejected",
    adminLabel: "Not Waived",
    bg: "danger",
  },
  paid: {
    userLabel: "Paid",
    adminLabel: "Paid",
    bg: "success",
  },
};

/**
 * @param {{ registrationDatum: RegistrationDatum, catecheticalYear?: number }}
 */
export function getPaymentStatus({
  registrationDatum: { payments },
  // parish,
  catecheticalYear,
}) {
  // if (parish === undefined) return "parisherror";
  const payment = payments.find((payment) => catecheticalYear === payment.year);
  const paymentStatus = payment === undefined ? "pending" : payment.status;
  return paymentStatus;
}

/**
 * @param {{ registrationDatum: RegistrationDatum, catecheticalYear?: number }}
 */
export function getWaiverReason({
  registrationDatum: { payments },
  // parish,
  catecheticalYear,
}) {
  // if (parish === undefined) return "error";
  const payment = payments.find((payment) => catecheticalYear === payment.year);
  const paymentStatus = payment === undefined ? "pending" : payment.status;
  const reason =
    paymentStatus === "waiverRequested" ? payment.waiverReason : "";
  return reason;
}

/**
 * @param {{ registrationDatum: RegistrationDatum, parish: ParishConfig, catecheticalYear: number, waiverReason: string, registrationCollection: firestore.CollectionReference<RegistrationDatum> }}
 */
export function requestWaiver({
  registrationDatum: { id, selectedParishId, payments },
  parish,
  catecheticalYear,
  waiverReason = "",
  registrationCollection,
}) {
  const payment = payments.find((payment) => catecheticalYear === payment.year);

  let updateOp;
  if (payment === undefined)
    updateOp = {
      payments: firestore.FieldValue.arrayUnion({
        recipientParish: parish.id,
        year: catecheticalYear,
        status: "waiverRequested",
        waiverReason,
      }),
    };
  else if (
    payment.status === "waiverRequested" ||
    payment.status === "waiverRejected"
  ) {
    payment.status = "waiverRequested";
    payment.waiverReason = waiverReason;
    updateOp = { payments };
  }

  return registrationCollection.doc(id).update(updateOp);
}

/**
 * @param {{ registrationDatum: RegistrationDatum, parish: ParishConfig, catecheticalYear: number, registrationCollection: firestore.CollectionReference<RegistrationDatum>, paymentAmount: number}}
 */
export function makeSuccessfulPayment({
  registrationDatum: { id, selectedParishId, payments },
  parish,
  catecheticalYear,
  registrationCollection,
  paymentAmount,
}) {
  const payment = payments.find((payment) => catecheticalYear === payment.year);

  let updateOp;
  if (payment === undefined) {
    updateOp = {
      payments: firestore.FieldValue.arrayUnion({
        recipientParish: parish.id,
        year: catecheticalYear,
        status: "paid",
        paymentAmountSgd: paymentAmount,
        paymentReceivedAt: firestore.Timestamp.now(),
      }),
    };
  } else {
    payment.recipientParish = parish.id;
    payment.year = catecheticalYear;
    payment.status = "paid";
    payment.paymentAmountSgd = paymentAmount;
    payment.paymentReceivedAt = firestore.Timestamp.now();

    updateOp = { payments };
  }

  return registrationCollection.doc(id).update(updateOp);
}
